import { segment } from 'src/lib/Segments'

import { hasMinWords } from '@utility/Utilities'

import { Section, SectionSubheader } from '..'

export default function ReviewTextInput({
  prompt,
  placeholder,
  value,
  setValue,
  required = false,
  multiline = false,
  maxCharacters = null,
  minWords = 3,
  onBlur = null,
  eventName = null,
  showError = false,
  errorVal = null,
  showValueError = false,
  containerCss = '',
  icon = null,
  customCss = '',
  type = null,
}: {
  prompt: string | null
  placeholder: string
  value: string
  setValue: (val: string) => void
  required?: boolean
  multiline?: boolean
  maxCharacters?: number
  minWords?: number
  onBlur?: () => void
  eventName?: string
  showError?: boolean
  errorVal?: string
  showValueError?: boolean
  containerCss?: string
  icon?: JSX.Element
  customCss?: string
  type?: string
}) {
  const remainingCharacters = (content: string, maxCharacters: number) => {
    return maxCharacters - (content === null ? 0 : content.length)
  }

  const checkError = () => {
    if (
      minWords !== null &&
      !hasMinWords(value, minWords) &&
      eventName !== 'advice_to_owner' &&
      showError
    ) {
      return true
    } else if (showValueError) {
      if (value?.length === 0 && showError) {
        return true
      }
      return false
    } else if (minWords == null && showError) {
      return true
    }
    return false
  }

  return (
    <div className={`container py-2 ${containerCss}`}>
      {prompt && (
        <SectionSubheader style="text-dark-900 font-semibold text-base" required={required}>
          {prompt}
        </SectionSubheader>
      )}
      <Section>
        {multiline && (
          <div className="relative">
            <textarea
              className={`!focus:outline-none w-full rounded-xl border border-light-40 px-4 py-3 focus:border-light-40 focus:ring-0 ${
                checkError() && 'border-red-400'
              }`}
              placeholder={placeholder}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              rows={3}
              onBlur={() => eventName && segment.review_input_deactivate(eventName)}
              onFocus={() => eventName && segment.review_input_activate(eventName)}
            />
            {checkError() && (
              <span className="absolute -bottom-4 right-3 text-xs text-red-400">
                {errorVal ?? 'Min 3 words'}
              </span>
            )}
          </div>
        )}
        {!multiline && (
          <div className="relative">
            <input
              type={type}
              className={`${customCss} h-10 w-full rounded-full border border-light-40 px-4 py-2 focus:outline-none ${
                checkError() && 'border-red-400'
              }`}
              placeholder={placeholder}
              value={value}
              onChange={(e) => {
                maxCharacters
                  ? setValue(e.target.value.substring(0, maxCharacters))
                  : setValue(e.target.value)
              }}
              onBlur={() => {
                eventName && segment.review_input_deactivate(eventName)
                onBlur?.()
              }}
              onFocus={() => eventName && segment.review_input_activate(eventName)}
            />
            {icon && <div className="absolute top-3 left-3">{icon}</div>}
            <div className="flex items-center justify-end pt-1">
              {checkError() && (
                <span className="text-xs text-red-400">{errorVal ?? 'Min 3 words'}</span>
              )}
              {maxCharacters && (
                <span className="pl-4 text-xs text-mid-300">{`${remainingCharacters(
                  value,
                  maxCharacters
                )}/80 characters remaining`}</span>
              )}
            </div>
          </div>
        )}
      </Section>
    </div>
  )
}
