import React from 'react'

const OICircularLoader = ({ size = 200, strokeWidth = 10 }) => {
  const radius = (size - strokeWidth) / 2
  const circumference = 2 * Math.PI * radius

  const visibleLength = circumference * 0.9 // 90% visible
  const gapLength = circumference - visibleLength // 10% gap

  return (
    <div className="flex items-center justify-center">
      <svg
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        className="animate-spin"
        style={{
          animation: 'rotate 2s linear infinite',
        }}
      >
        <defs>
          {/* Gradient for stroke fading */}
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#6865A2" stopOpacity="1" />
            <stop offset="100%" stopColor="#6865A2" stopOpacity="0" />
          </linearGradient>
        </defs>

        {/* Progress Circle */}
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke="url(#gradient)"
          strokeWidth={strokeWidth}
          fill="transparent"
          strokeDasharray={`${visibleLength} ${gapLength}`}
          strokeLinecap="butt"
        />
      </svg>

      {/* Add keyframes animation */}
      <style jsx>{`
        @keyframes rotate {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </div>
  )
}

export default OICircularLoader
