import { format } from 'date-fns'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useContext, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { BeatLoader } from 'react-spinners'
import { getListings } from 'src/data/newListings'
import { segment } from 'src/lib/Segments'
import { APContextReview } from 'src/pages/review/[buildingSlug]'

import OIIcon from '@components/icons'
import UnitCards from '@components/newUnit/unitCards'
import { getNewNeighborhood } from '@redux/actions/newSearch'
import { useAppSelector, useThunkDispatch } from '@redux/hooks'
import { fullMonthName } from '@utility/Utilities'

import { ActionButton, Separator } from '..'

const ReviewSuccess = () => {
  const router = useRouter()
  const dispatch = useThunkDispatch()
  const { buildingData, reviewData } = useContext(APContextReview)
  const [listingsData, setListingsData] = useState(null)
  const { neighborhoods } = useAppSelector((state) => state.newSearch)
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    segment.review_success()
  }, [])

  useEffect(() => {
    if (!neighborhoods) {
      // Todo Change api to listing service
      dispatch(getNewNeighborhood())
    }
  }, [])

  const getMonthThreeMonthsFromNow = (): string => {
    const today = new Date()
    today.setMonth(today.getMonth() + 3)
    return fullMonthName(today)
  }

  useEffect(() => {
    if (neighborhoods?.length > 0) {
      getFiltereListings()
    }
  }, [neighborhoods])

  async function getFiltereListings() {
    try {
      const today = new Date()
      today.setMonth(today.getMonth() + 3) // Move three months ahead

      const startDate = new Date(today.getFullYear(), today.getMonth(), 1)
      const endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0)

      const nbrs = []
      neighborhoods.map((x) => {
        if (x.borough == buildingData.neighborhood.borough) {
          nbrs.push(x.id)
        }
      })

      const data = {
        filters: {
          neighborhoodIds: nbrs,
          minExpectedMoveInDate: format(startDate, 'yyyy-MM-dd'),
          maxExpectedMoveInDate: format(endDate, 'yyyy-MM-dd'),
          maxRent: reviewData?.startingRent
            ? reviewData.startingRent * 1.05 < 2500
              ? 2500
              : reviewData.startingRent * 1.05
            : 2500,
        },
      }

      const listings = await getListings(null, data, 1)
      setListingsData(listings.results.slice(0, 4))
    } catch {
      console.log('error')
    } finally {
      setLoader(false)
    }
  }

  return (
    <div className="mx-auto mt-4 max-w-screen-lg px-4 sm:mt-0">
      <>
        <div className="w-full space-y-3 py-4 text-left text-dark-700">
          <OIIcon name={'checkVerified'} size="xl" className="text-green-400" />
          <p className="text-2xl font-semibold">Your review has been submitted for approval.</p>
          <div className="pb-4 text-sm">
            We will let you know when it's published. You can access or edit your reviews on{' '}
            <Link href="/profile">
              <span className="w-full cursor-pointer text-bluegray-300 underline" onClick={()=>{
                segment?.review_goto_profile()
              }}>
                your profile
              </span>
            </Link>
          </div>
          {buildingData?.primaryAddress?.cityId !== 'nyc' ? (
            <ActionButton
              label={'Done'}
              onClick={() => router.push('/profile?q=reviews')}
              size="none"
              customStyle="px-6 mt-2"
            />
          ) : (
            <Separator />
          )}
        </div>
        {buildingData?.primaryAddress?.cityId == 'nyc' && (
          <div className="my-4">
            <div className="text-2xl font-semibold text-dark-700">
              Search highly-reviewed apartments on openigloo
            </div>
            <div className="mt-6 block rounded-md bg-light-10 p-4 shadow sm:hidden">
              <div className="text-xs font-semibold text-dark-700">LOCATION</div>
              <div className="pt-2 text-sm text-dark-900">
                {buildingData?.neighborhood?.borough}
              </div>
            </div>
            <div className="mt-2 flex w-full items-center space-x-2 sm:mt-6 sm:w-fit">
              {!isMobile && (
                <div className="hidden rounded-md bg-light-10 p-4 shadow sm:block">
                  <div className="text-xs font-semibold text-dark-700">LOCATION</div>
                  <div className="pt-2 text-sm text-dark-900">
                    {buildingData?.neighborhood?.borough}
                  </div>
                </div>
              )}
              <div className="w-full rounded-md bg-light-10 p-4 shadow sm:w-fit">
                <div className="text-xs font-semibold text-dark-700">PRICE</div>
                <div className="pt-2 text-sm text-dark-900">
                  Max $
                  {reviewData?.startingRent * 1.05 < 2500
                    ? 2500
                    : (reviewData.startingRent * 1.05).toFixed(0)}
                </div>
              </div>
              <div className="w-full rounded-md bg-light-10 p-4 shadow sm:w-fit">
                <div className="text-xs font-semibold text-dark-700">EXPECTED MOVE-IN</div>
                <div className="pt-2 text-sm text-dark-900">{getMonthThreeMonthsFromNow()}</div>
              </div>
            </div>
            {listingsData?.length > 0 && (
              <div className="mt-6 grid grid-cols-1 gap-4 sm:grid-cols-2">
                {listingsData.map((x) => {
                  return <UnitCards data={x} key={x.id} segmentBase='review'/>
                })}
              </div>
            )}
            {loader && (
              <div className="mx-auto mt-8 mb-16 text-center">
                <BeatLoader size={10} color="#C1C7D0" />
              </div>
            )}
            <div className="mt-4 py-4 text-dark-700">
              <ActionButton
                label={'Search apartments'}
                size="small"
                customStyle="!py-3 !text-sm !px-6 w-full sm:w-fit"
                onClick={() => {
                  segment?.review_success_explorelistings()
                  router.push('/listings?showOnboarding=true')
                }}
              />
            </div>
          </div>
        )}
      </>
    </div>
  )
}

export default ReviewSuccess
