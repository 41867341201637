import React from 'react'

type SectionDescType = {
  style?: string
  children?: React.ReactChild
}

export default function SectionDescription({ children = '', style = '' }: SectionDescType) {
  return <p className={`mt-2 mb-2 text-base font-light ${style}`}>{children}</p>
}
