import React from 'react'
import { segment } from 'src/lib/Segments'

import OIIcon from '@components/icons'

interface ShareProps {
  postTitle: string
  url: string
  label?: string
  containerStyle?: string
  segmentBase?: string
  showIcon?: boolean
}

const MobileNativeShare = ({
  postTitle,
  url,
  label = 'Share',
  containerStyle = '',
  segmentBase = 'other',
  showIcon = true,
}: ShareProps) => {
  const handleOnClick = async () => {
    if (navigator.share) {
      segment.global_share_native?.(segmentBase, url)
      await navigator.share({
        title: `${postTitle}`,
        text: `${postTitle}`,
        url: `${url}`,
      })
    }
  }

  return (
    <div
      className={
        containerStyle ||
        'flex cursor-pointer flex-row items-center space-x-2 rounded-3xl border border-light-40 px-2 py-2 text-sm font-thin text-dark-600'
      }
      onClick={() => {
        handleOnClick()
      }}
    >
      {showIcon && <OIIcon name="share" size="md" />}
      <span>{label}</span>
    </div>
  )
}

export default MobileNativeShare
