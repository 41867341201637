import { useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { segment } from 'src/lib/Segments'
import useOnClickOutside from 'use-onclickoutside'

type SortOption = {
  id: string
  label: string
  icon?: JSX.Element
}

interface Props {
  sortOptions: SortOption[]
  onSelect: (SortOption) => void
  selectedOptionId: string
  segmentBase?: string
}

const OISortButton = ({ sortOptions, selectedOptionId, onSelect, segmentBase }: Props) => {
  const [showOptions, setShowOptions] = useState(false)

  const ref = useRef(null)
  useOnClickOutside(ref, () => setShowOptions(false))

  useEffect(() => {
    setShowOptions(false)
  }, [selectedOptionId])

  return (
    <div className="relative" ref={ref}>
      <div
        onClick={() => {
          setShowOptions(!showOptions)
          if (segmentBase) {
            try {
              segment.global_sort_dropdown(segmentBase)
            } catch (error) {
              console.log(error)
            }
          }
        }}
        className="flex cursor-pointer items-center space-x-2 rounded-full border border-light-40 py-1 px-2 pl-3 text-sm text-dark-600"
      >
        <span>Sort</span>
        {!showOptions ? (
          <FiChevronDown size={18} className="text-primary" />
        ) : (
          <FiChevronUp size={18} className="text-primary" />
        )}
      </div>
      {showOptions && (
        <>
          <div
            className="fixed inset-0 z-[50] h-screen w-screen bg-black/20 md:hidden"
            onClick={() => setShowOptions(false)}
          ></div>
          <ul
            className={`${
              isMobile ? 'slide-up' : ''
            } fixed bottom-0 right-0 z-[51] mt-2 w-screen min-w-max select-none space-y-2 rounded-t-3xl border border-light-20 bg-white py-8  shadow-lg md:absolute md:top-full md:bottom-auto md:w-auto md:space-y-1 md:rounded-t-lg md:rounded-b-xl md:py-1`}
          >
            <li className="absolute left-1/2 top-2 h-2 w-16  -translate-x-1/2 rounded-lg bg-light-50 md:hidden"></li>
            <li className="px-4 font-semibold md:hidden">Sort: </li>
            {sortOptions.map((so) => (
              <li
                onClick={() => onSelect(so)}
                className={`flex cursor-pointer items-center space-x-2 px-4 py-2 text-dark-900 hover:bg-blue-25 md:text-sm ${
                  selectedOptionId === so.id ? 'bg-blue-25' : ''
                }`}
                key={so.id}
              >
                {so.icon}
                <span>{so.label}</span>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  )
}

export default OISortButton
