import React, { useEffect } from 'react'
import { FiAlertTriangle } from 'react-icons/fi'
import { segment } from 'src/lib/Segments'

import ActionButton from '@components/buttons/actionButton'

function SubmissionErrorModal({ onClose, onSubmit }) {
  useEffect(() => {
    segment?.review_submit_failed()
  }, [])
  return (
    <div
      className="fixed inset-0 z-[100] w-full overflow-hidden overflow-y-scroll bg-black bg-opacity-90 text-black"
      id="background"
      onClick={() => onClose()}
    >
      <div
        className="z-50 mx-auto my-32 w-1/2 overflow-hidden rounded-2xl bg-white p-4 sm:max-h-[calc(100vh-76px)] lg:w-4/12"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex flex-col items-center text-center">
          <FiAlertTriangle size={40} className="mb-4" color="FF991F" />
          <div className="font-semibold text-dark-900">We could not submit your review</div>
          <div className="pt-2 pb-4 text-sm text-dark-700">
            It could be due to poor network connection or an error from our end. Please try again.
          </div>
          <>
            <ActionButton
              label={'Submit review again'}
              size="none"
              customStyle="text-sm my-2 w-44"
              onClick={() => {
                segment?.review_submit_failed_submit_again()
                onSubmit()
              }}
            />
            <ActionButton
              label={'Okay'}
              size="none"
              customStyle="text-sm my-2 w-44 rounded-full bg-light-0 border border-light-40 !text-dark-900"
              onClick={() => {
                segment?.review_submit_failed_go_back()
                onClose()
              }}
            />
          </>
        </div>
      </div>
    </div>
  )
}

export default SubmissionErrorModal
