import { isMobile } from 'react-device-detect'
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs'
import { segment } from 'src/lib/Segments'

import { ReviewSteps } from '@openigloo/common'
import { toNextStage, toPrevStage } from '@redux/actions/userReview'
import { useAppSelector, useThunkDispatch } from '@redux/hooks'

import { ActionButton } from '..'

type Props = {
  reviewStage: number
  buildingSlug: string
  canProceed: () => boolean
  validateError: (params: boolean) => void
  isSavingPhotos: boolean
  isSubmitting: boolean
  reviewSubmit: any
}

function ReviewButtons({
  reviewStage,
  buildingSlug,
  canProceed,
  validateError,
  isSavingPhotos,
  isSubmitting,
  reviewSubmit,
}: Props) {
  const dispatch = useThunkDispatch()
  const { lastStage } = useAppSelector((state) => state.currentUserReview)
  const { profile } = useAppSelector((state) => state.currentUser)

  function BackDisabled() {
    if (isSavingPhotos || isSubmitting) {
      return true
    }
    if (reviewStage !== ReviewSteps.RATING) {
      return false
    }
    return true
  }

  function NextDisabled() {
    return !canProceed()
  }

  return (
    <div className="flex justify-between py-6 px-3 sm:px-6 md:px-0">
      <ActionButton
        label={
          <div className="flex items-center space-x-2 text-sm">
            <BsArrowLeft /> <div>Back</div>
          </div>
        }
        customStyle={`${
          BackDisabled() ? 'bg-light-30 opacity-100 !text-mid-100' : 'bg-blue-25 !text-dark-500'
        }`}
        size={isMobile ? 'xsmall' : 'small'}
        style="none"
        onClick={() => {
          dispatch(toPrevStage())
          segment.review_prev_stage(buildingSlug, reviewStage, profile?.email)
        }}
        disabled={BackDisabled()}
      />
      {reviewStage !== lastStage ? (
        <ActionButton
          label={
            <div className="flex items-center space-x-2 text-sm">
              <div>Next</div> <BsArrowRight />
            </div>
          }
          customStyle={`${
            NextDisabled() ? 'bg-light-30 !text-mid-100' : 'bg-blue-25 !text-dark-500 '
          }`}
          size={isMobile ? 'xsmall' : 'small'}
          style="none"
          onClick={() => {
            if (NextDisabled()) {
              validateError(true)
            } else {
              validateError(false)
              segment.review_next_stage(buildingSlug, reviewStage, profile?.email)
              dispatch(toNextStage())
            }
          }}
        />
      ) : (
        <ActionButton
          label="Submit review"
          onClick={() => reviewSubmit()}
          isLoading={isSubmitting}
          size={isMobile ? 'xsmall' : 'small'}
          customStyle="text-sm"
          disabled={isSubmitting}
        />
      )}
    </div>
  )
}

export default ReviewButtons
