import getConfig from 'next/config'
import Image from 'next/image'
import Link from 'next/link'
import useLogout from 'src/hooks/useLogout'
import { segment } from 'src/lib/Segments'

import OIIcon from '@components/icons'
import { setAuthRequired } from '@redux/actions'
import { useAppSelector, useThunkDispatch } from '@redux/hooks'

import SocialLinks from './SocialLinks'

const { publicRuntimeConfig } = getConfig()
const openiglooLogo = `${publicRuntimeConfig.STATIC_ROOT}/logo.png`

export default function DesktopFooter() {
  const { isLoggedIn, profile } = useAppSelector((state) => state.currentUser)

  const logout = useLogout()
  const dispatch = useThunkDispatch()

  return (
    <nav className="z-[21] bg-light-20 text-black">
      <div className="mx-auto flex w-full max-w-screen-lg flex-row flex-nowrap items-start justify-evenly p-4">
        <div className="flex flex-row items-center justify-between py-4">
          <div className="flex w-max flex-col items-center">
            <a href="/" className="relative w-1/2" onClick={segment.footer_logo}>
              <div className="relative mb-2 flex h-20 w-full">
                <Image
                  src={openiglooLogo}
                  alt="Home"
                  className="z-0"
                  layout="fill"
                  objectFit="contain"
                  objectPosition="center"
                  unoptimized
                />
              </div>
            </a>
            <SocialLinks />
            <p className="my-2 text-xs text-gray-500 sm:text-right">
              © {new Date().getFullYear()}, openigloo Inc. All Rights Reserved.
            </p>
            <p className="pl-3 text-xs text-gray-500">{`Version ${publicRuntimeConfig.APP_VERSION}`}</p>
          </div>
        </div>
        <div className="my-2 flex flex-col space-y-2">
          <h3 className="text-lg font-semibold">Account</h3>
          {isLoggedIn && profile ? (
            <>
              <Link href="/profile">
                <p
                  className="flex cursor-pointer items-center space-x-2"
                  onClick={segment.footer_profile}
                >
                  <OIIcon name="profile" size="md" />
                  <span className="w-36 truncate">{profile.email}</span>
                </p>
              </Link>
              <div
                className="flex cursor-pointer items-center space-x-2"
                onClick={() => {
                  segment.footer_logout
                  logout()
                }}
              >
                <OIIcon name="logout" size="md" />
                <h4>Logout</h4>
              </div>
            </>
          ) : (
            <div
              className="flex cursor-pointer items-center space-x-2"
              onClick={() => {
                segment.footer_login()
                dispatch(setAuthRequired(true))
              }}
            >
              <h4 className="text-sm">Login</h4>
              <OIIcon name="profile" size="md" />
            </div>
          )}
        </div>
        <div className="my-2 flex flex-col space-y-2">
          <h3 className="text-lg font-semibold">Support</h3>
          <a
            className="text-sm"
            href="mailto:info@openigloo.com"
            onClick={segment.footer_contactus}
          >
            <h4>Contact Us</h4>
          </a>
          <a className="text-sm" href="/faqs" onClick={segment.footer_faq}>
            <h4>FAQs</h4>
          </a>
        </div>
        <div className="my-2 flex flex-col space-y-2">
          <h3 className="text-lg font-semibold">Community</h3>
          <a
            className="text-sm"
            href="/nyc/apartments-for-rent-nyc"
            onClick={segment.footer_verified_listings}
          >
            <h4>Verified Apartment Listings</h4>
          </a>
          <a
            className="text-sm"
            href="/nyc/furnished-listings"
            onClick={segment.footer_furnished_listings}
          >
            <h4>openigloo Furnished NYC Apartments</h4>
          </a>
          <a
            className="text-sm"
            href="/community-guidelines"
            onClick={segment.footer_community_guidelines}
          >
            <h4>Community Guildelines</h4>
          </a>
          <a className="text-sm" href="/blogs" onClick={segment.footer_blogs}>
            <h4>Our Blogs</h4>
          </a>
          <a className="text-sm" href="/buildings" onClick={segment.footer_neighborhoods}>
            NYC Buildings and Landlords
          </a>
          <a className="text-sm" href="/cities/boston" onClick={segment.footer_neighborhoods}>
            Boston Buildings
          </a>
          <a
            className="text-sm"
            href="/blogs/rent-reports"
            onClick={segment.footer_nyclease_renewals_report}
          >
            <h4>NYC Lease Renewals Reports</h4>
          </a>
        </div>
        <div className="my-2 flex flex-col space-y-2">
          <h3 className="text-lg font-semibold">For Landlords</h3>
          <a
            className="text-sm"
            href="https://manager.openigloo.com/"
            onClick={segment.footer_submit_listings}
          >
            <h4>Submit Your Apartment Listings</h4>
          </a>
          <a
            className="text-sm"
            href="https://manager.openigloo.com/listings-quality-policy"
            onClick={segment.footer_listings_quality_policy}
          >
            <h4>Listings Quality Policy</h4>
          </a>
          <a
            className="text-sm"
            href="https://manager.openigloo.com/"
            onClick={segment.footer_owners_managers}
          >
            <h4>For Owners & Managers</h4>
          </a>
        </div>
      </div>
      {/* <div className="mx-auto flex max-w-screen-lg flex-col items-end">
        <AppstoreDownloads />
      </div> */}
    </nav>
  )
}
