import Image from 'next/image'
import { segment } from 'src/lib/Segments'

import ActionButton from '@components/buttons/actionButton'
import { ReviewSteps } from '@openigloo/common'
import sadface from '@public/sad-face.svg'
import { useThunkDispatch } from '@redux/hooks'
import { userReviewSlice } from '@redux/reducers/userReview'

type props = {
  setShowIncompletePopup: (v: boolean) => void
}

function IncompleteReview({ setShowIncompletePopup }: props) {
  const dispatch = useThunkDispatch()

  return (
    <div
      className="fixed inset-0 z-[100] w-full overflow-hidden overflow-y-scroll bg-black bg-opacity-90 text-black"
      id="background"
      onClick={() => setShowIncompletePopup(false)}
    >
      <div
        className="z-50 mx-auto my-32 w-11/12 overflow-hidden rounded-2xl bg-white p-4 sm:max-h-[calc(100vh-76px)] lg:w-4/12"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="text-center">
          <Image src={sadface} alt="wifioff" />
          <div className="pt-4 font-semibold text-dark-900">Wait up! Your review is incomplete</div>
          <div className="pt-2 pb-4 text-sm text-dark-700">
            Please go back and complete the review before submitting.
          </div>
          <>
            <ActionButton
              label={'Try again from start'}
              size="none"
              customStyle="text-sm"
              onClick={() => {
                segment?.review_submit_incomplete()
                dispatch(userReviewSlice.actions.setStage(ReviewSteps.RATING))
                setShowIncompletePopup(false)
              }}
            />
          </>
        </div>
      </div>
    </div>
  )
}

export default IncompleteReview
