import { useRouter } from 'next/router'
import { createContext, useEffect, useRef, useState } from 'react'
import { BeatLoader } from 'react-spinners'
import { reviewInfo } from 'src/common/Constants'
import { BuildingApi, ReviewApi, ReviewerApi } from 'src/common/types'
import { getBuildingApi } from 'src/data/Buildings'
import {
  addReview,
  getReviewById,
  getUserReviews,
  reviewSubmitApi,
  reviewUploadPhotos,
  updateReview,
} from 'src/data/Review'
import { segment } from 'src/lib/Segments'
import isEmail from 'validator/lib/isEmail'
import isMobilePhone from 'validator/lib/isMobilePhone'

import OICircularLoader from '@components/UI/atoms/OICircularLoader'
import { CreateReviewPrompt } from '@components/auth/AuthPrompts'
import { toast } from '@components/errors/ToastManager'
import { LoginModal } from '@components/index'
import CreatePricing from '@components/review/CreatePricing'
import CreateProsCons from '@components/review/CreateProsCons'
import CreateRatings from '@components/review/CreateRatings'
import CreateReviewSuccess from '@components/review/CreateReviewSuccess'
import CreateReviewerDemog from '@components/review/CreateReviewerDemog'
import CreateTenancy from '@components/review/CreateTenancy'
import ReviewHeader from '@components/review/Header'
import ReviewButtons from '@components/review/ReviewButtons'
import SubmissionErrorModal from '@components/review/SubmissionErrorModal'
import UploadPhotos from '@components/review/UploadPhotos'
import IncompleteReview from '@components/review/incompleteReview'
import NetworkDisconnect from '@components/review/networkDisconnect'
import { ReviewSteps } from '@openigloo/common'
// import wifioff from '@public/wifi-off.svg'
import { requireLogin, setAuthRequired, updateReviewerData } from '@redux/actions'
import { toNextStage } from '@redux/actions/userReview'
import { useAppSelector, useThunkDispatch } from '@redux/hooks'
import { userSlice } from '@redux/reducers/user'
import { userReviewSlice } from '@redux/reducers/userReview'
import { hasMinWords } from '@utility/Utilities'

interface APContextReview {
  buildingData?: BuildingApi
  reviewData?: ReviewApi
  setReviewData?: (data: ReviewApi) => void
  displayPhotoList?: any
  setDisplayPhotoList?: (data: any) => void
  getReview?: (id: string) => void
  rvData?: ReviewerApi
  setRvData?: (data: ReviewerApi) => void
}

export const APContextReview = createContext<APContextReview>(null)

function ReviewStages() {
  const router = useRouter()
  const dispatch = useThunkDispatch()
  const { isLoggedIn, token, authRequired, reviewerData } = useAppSelector(
    (state) => state.currentUser
  )
  const [buildingSlug, setBuildingSlug] = useState(null)
  const [buildingData, setBuildingData] = useState(null)
  const [reviewData, setReviewData] = useState<ReviewApi>({})
  const [rvData, setRvData] = useState<ReviewerApi>({})
  const [showError, setShowError] = useState(false)
  const [loader, setLoader] = useState(false)
  const [isSavingPhotos, setIsSavingPhotos] = useState(false)
  const [initialloader, setInitialLoader] = useState(true)
  const [isSubmitting] = useState(false)
  const [displayPhotoList, setDisplayPhotoList] = useState([])
  const [editedData, setEditedData] = useState(null)
  const [submissionInProcess, setSubmissionInProcess] = useState(false)
  const [isFinalSubmit, setIsFinalSubmit] = useState(false)

  const [isOnline, setIsOnline] = useState(true)
  const [showOfflinePopup, setShowOfflinePopup] = useState(false)
  const [showIncompletePopup, setShowIncompletePopup] = useState(false)
  const [currentLoadingStep, setCurrentLoadingStep] = useState<string | null>(null)
  const [submissionError, setSubmissionError] = useState(false)
  const intervalRef = useRef(null)

  const { stage: reviewStage } = useAppSelector((state) => state.currentUserReview)

  const reviewStepComponents = {
    [ReviewSteps.RATING]: <CreateRatings showError={showError} />,
    [ReviewSteps.PROSCONS]: <CreateProsCons showError={showError} />,
    [ReviewSteps.TENANCY]: <CreateTenancy showError={showError} />,
    [ReviewSteps.RENTPRICES]: <CreatePricing showError={showError} />,
    [ReviewSteps.UPLOAD]: (
      <UploadPhotos
        setIsSavingPhotos={(x) => setIsSavingPhotos(x)}
        segmentBase="review"
        isOnline={isOnline}
        setShowOfflinePopup={setShowOfflinePopup}
      />
    ),
    [ReviewSteps.DEMOG_SUBMIT]: <CreateReviewerDemog />,
    [ReviewSteps.SUCCESS]: <CreateReviewSuccess />,
  }

  useEffect(() => {
    if (showError) {
      toast.show({
        message: 'See fields marked in red.',
        title: 'Complete required fields to continue',
        duration: 4000,
      })
    }
  }, [showError])

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof navigator !== 'undefined') {
      if (reviewStage !== ReviewSteps.SUCCESS) {
        if (!intervalRef.current) {
          const checkConnection = () => {
            setIsOnline(navigator.onLine)
          }

          // Initial check
          checkConnection()

          // Set interval for periodic checks
          intervalRef.current = setInterval(checkConnection, 5000)
        }
      } else {
        if (intervalRef.current) {
          clearInterval(intervalRef.current) // Clear interval when reviewStage is SUCCESS
          intervalRef.current = null
        }
      }
    }

    // Cleanup on unmount
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
        intervalRef.current = null
      }
    }
  }, [reviewStage])

  useEffect(() => {
    if (reviewerData && isLoggedIn) {
      setRvData(reviewerData)
    }
  }, [reviewerData])

  useEffect(() => {
    if (isOnline) {
      setShowOfflinePopup(false)
    } else {
      setShowOfflinePopup(true)
    }
  }, [isOnline])

  useEffect(() => {
    if (router.query?.buildingSlug) {
      setBuildingSlug(router.query.buildingSlug)
      getBuildingData()
    }
  }, [router.query])

  useEffect(() => {
    return () => {
      dispatch(userReviewSlice.actions.setStage(ReviewSteps.RATING))
    }
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [reviewStage])

  useEffect(() => {
    if (isLoggedIn) {
      setInitialLoader(true)
      getUserReview()
    } else {
      setTimeout(() => {
        setInitialLoader(false)
      }, 2000)
    }
  }, [isLoggedIn])

  async function getBuildingData() {
    const data = await getBuildingApi(router.query.buildingSlug as string, null, token)
    setBuildingData(data)
  }

  async function getUserReview() {
    if (isLoggedIn) {
      const review = await getUserReviews(token)
      const data = review?.find((x) => x.buildingSlug === router.query.buildingSlug) ?? null
      if (!data?.id) {
        const createData = await addReview(token, {
          buildingSlug: router.query.buildingSlug as string,
          submissionPlatform: 'web',
        })
        setReviewData(createData)
        return createData
      } else {
        setReviewData(data)
        return data
      }
    }
  }

  async function getReview(id: string) {
    const data = await getReviewById(token, id)
    setReviewData(data)
  }

  function canProceed() {
    if (isSavingPhotos) {
      return false
    }
    switch (reviewStage) {
      case ReviewSteps.RATING:
        return reviewInfo.every(
          (r) => reviewData?.[r.key] !== null && reviewData?.[r.key] !== undefined
        )
      case ReviewSteps.PROSCONS:
        return (
          hasMinWords(reviewData.pros, 3) &&
          hasMinWords(reviewData.cons, 3) &&
          hasMinWords(reviewData.title, 3)
        )
      case ReviewSteps.TENANCY:
        return (
          reviewData.moveIn &&
          reviewData.moveOut &&
          typeof reviewData.recommendBuilding === 'boolean' &&
          typeof reviewData.approveOwner === 'boolean' &&
          (!reviewData.landlordEmail && !reviewData.landlordPhone
            ? true
            : isEmail(reviewData.landlordEmail ?? '') ||
              isMobilePhone(reviewData.landlordPhone ?? ''))
        )
      case ReviewSteps.RENTPRICES:
        return (
          reviewData?.startingRent !== null &&
          reviewData?.startingRent?.toString()?.length > 0 &&
          reviewData?.endingRent !== null &&
          reviewData?.endingRent?.toString()?.length > 0
        )
      default:
        return true
    }
  }

  async function onSaveAndExit() {
    if (isLoggedIn) {
      if (!isOnline) {
        setShowOfflinePopup(true)
        return
      }
      router.push('/profile?q=reviews')
    } else {
      const newData = reviewData
      const newDemog = rvData
      requireLogin(async () => {
        setIsFinalSubmit(false)
        setEditedData({ newData: newData, newDemog: newDemog })
      })
    }
  }

  useEffect(() => {
    if (
      isLoggedIn &&
      editedData &&
      reviewData &&
      reviewData.id &&
      !submissionInProcess &&
      isFinalSubmit !== null
    ) {
      setSubmissionInProcess(true)
      setCurrentLoadingStep('account_setup')
      segment?.review_submit_creating_account()
      updateReviewAndExit(editedData.newData, editedData.newDemog)
    }
  }, [isLoggedIn, editedData, reviewData, isFinalSubmit])

  async function updateReviewAndExit(newData: ReviewApi, newDemog: ReviewerApi) {
    if (!isOnline) {
      setShowOfflinePopup(true)
      return
    }
    setLoader(true)
    try {
      setCurrentLoadingStep('saving_review')
      segment?.review_submit_saving_review()
      const newDataReview = await updateReview(token, newData, reviewData.id)
      setReviewData(newDataReview)
      if (newDemog) {
        await dispatch(updateReviewerData(token, newDemog))
      }
      if (displayPhotoList.length > 0) {
        setCurrentLoadingStep('image_upload')
        segment?.review_submit_uploading_images()
        const uploadPromises = displayPhotoList.map((x, index) => {
          const imageObj = {
            file: x.file,
            order: index + 1,
          }
          return reviewUploadPhotos(token, imageObj, reviewData.id)
        })
        await Promise.all(uploadPromises)
      }
      if (isFinalSubmit) {
        setCurrentLoadingStep('review_submit')
        segment?.review_submit_submitting()
        try {
          await reviewSubmitApi(token, reviewData.id)
        } catch (e) {
          console.log(e)
          setLoader(false)
          isOnline && setShowIncompletePopup(true)
          return
        }
        dispatch(userSlice.actions.setReviewerData({ ...reviewerData, hasReviewerAccess: true }))
        await getReview(reviewData.id)
        setEditedData(null)
        setIsFinalSubmit(null)
        dispatch(userReviewSlice.actions.setStage(ReviewSteps.SUCCESS))
      } else {
        router.push('/profile?q=reviews')
        setEditedData(null)
        setIsFinalSubmit(null)
      }
    } catch {
      console.log('err')
      setSubmissionError(true)
    } finally {
      setLoader(false)
      setSubmissionInProcess(false)
      setIsFinalSubmit(null)
      setCurrentLoadingStep(null)
    }
  }

  async function reviewSubmit() {
    if (!isOnline) {
      setShowOfflinePopup(true)
      return
    }
    segment.review_submit(buildingSlug.toString(), reviewStage, reviewerData?.email)
    if (isLoggedIn) {
      setLoader(true)
      try {
        setCurrentLoadingStep('saving_review')
        segment?.review_submit_saving_review()
        await updateReview(token, reviewData, reviewData.id)
        try {
          setCurrentLoadingStep('review_submit')
          segment?.review_submit_submitting()
          await reviewSubmitApi(token, reviewData.id)
        } catch (e) {
          console.log(e)
          setLoader(false)
          isOnline && setShowIncompletePopup(true)
          return
        }
        dispatch(userSlice.actions.setReviewerData({ ...reviewerData, hasReviewerAccess: true }))
        dispatch(toNextStage())
      } catch {
        setSubmissionError(true)
        console.log('err')
      } finally {
        setCurrentLoadingStep(null)
        setLoader(false)
      }
    } else {
      const newData = reviewData
      const newDemog = rvData
      requireLogin(async () => {
        setIsFinalSubmit(true)
        setEditedData({ newData: newData, newDemog: newDemog })
      })
    }
  }

  useEffect(() => {
    if (buildingData && reviewData?.id) {
      setInitialLoader(false)
    }
  }, [reviewData, buildingData])

  const getLoadingText = () => {
    switch (currentLoadingStep) {
      case 'account_setup':
        return 'We are setting up your account.'
      case 'saving_review':
        return 'We are saving your review.'
      case 'image_upload':
        return 'Your images are uploading.'
      case 'review_submit':
        return 'Finally, your review is being submitted for approval.'
    }
  }

  const contextValue = {
    buildingData,
    reviewData,
    setReviewData,
    displayPhotoList,
    setDisplayPhotoList,
    getReview,
    rvData,
    setRvData,
  }

  return (
    <APContextReview.Provider value={contextValue}>
      <div className="relative h-full min-h-screen bg-white pb-12 sm:pb-0">
        <ReviewHeader
          currentStep={reviewStage}
          buildingSlug={buildingSlug}
          onSaveAndExit={onSaveAndExit}
          setLoader={(x) => setLoader(x)}
        />
        <div className="mx-auto max-w-screen-md bg-white sm:my-6">
          {initialloader ? (
            <div className="my-24 text-center">
              <BeatLoader size={10} color="#8380A9" />
            </div>
          ) : loader && currentLoadingStep ? (
            <div className="w-7/10 my-auto mt-10 flex flex-col items-center">
              <OICircularLoader size={50} strokeWidth={6} />
              <div className="mt-4 text-xl font-semibold">One moment...</div>
              <div className="mt-4 text-center text-base text-dark-700">{getLoadingText()}</div>
            </div>
          ) : (
            <>
              {reviewStepComponents[reviewStage]}
              {reviewStage !== ReviewSteps.SUCCESS && (
                <ReviewButtons
                  buildingSlug={buildingSlug?.toString()}
                  isSavingPhotos={isSavingPhotos}
                  reviewStage={reviewStage}
                  canProceed={canProceed}
                  reviewSubmit={reviewSubmit}
                  validateError={(x) => setShowError(x)}
                  isSubmitting={isSubmitting}
                />
              )}
            </>
          )}
        </div>
        {!isLoggedIn && authRequired.loginRequired && (
          <LoginModal
            AuthPrompt={CreateReviewPrompt}
            onClose={() => {
              dispatch(setAuthRequired(false))
            }}
          />
        )}
        {loader && !currentLoadingStep && (
          <div className="absolute top-0 h-full w-screen bg-white opacity-70">
            <div className="relative h-full">
              <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                <BeatLoader size={12} color="#061938" />
              </div>
            </div>
          </div>
        )}
        {showOfflinePopup && <NetworkDisconnect setShowOfflinePopup={setShowOfflinePopup} />}
        {showIncompletePopup && (
          <IncompleteReview setShowIncompletePopup={setShowIncompletePopup} />
        )}
        {submissionError && (
          <SubmissionErrorModal
            onSubmit={updateReviewAndExit}
            onClose={() => setSubmissionError(false)}
          />
        )}
      </div>
    </APContextReview.Provider>
  )
}

export default ReviewStages
