import React from 'react'

import type { CategoryTypes } from '@openigloo/common'
import { baseTitleCase } from '@utility/Utilities'

type PillProps = {
  label: string
  color?: 'yellow' | 'purple' | 'red' | 'green' | 'darkRed' | 'white' | 'blue'
  category?: CategoryTypes
  pillStyle?: string
  labelStyle?: string
}

const Pill = ({
  label = 'Open Violations: 17',
  color = 'white',
  pillStyle = '',
  labelStyle = '',
}: PillProps): JSX.Element => {
  const styles = {
    white: 'bg-light-10 text-dark-600 border-light-40',
    yellow: 'bg-yellow-50 border-yellow-100 text-blue-300',
    purple: 'bg-blue-25 border-bluegray-75 text-blue-200',
    red: 'border-red-75 bg-red-50  text-red-500',
    green: 'bg-green-50 border-green-75 text-green-500',
    blue: 'bg-blue-300',
  }
  return (
    <div
      className={`inline-flex items-center rounded-2xl border px-2 ${pillStyle} ${styles[color]}`}
    >
      <span className={labelStyle}>{baseTitleCase(label, '_')}</span>
    </div>
  )
}

export default Pill
