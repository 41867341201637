import { useContext } from 'react'
import { updateReview } from 'src/data/Review'
import { APContextReview } from 'src/pages/review/[buildingSlug]'

import { useAppSelector } from '@redux/hooks'

import ReviewTextInput from './ReviewTextInput'

type Props = {
  showError: boolean
}

function CreateProsCons({ showError }: Props) {
  const { reviewData, setReviewData } = useContext(APContextReview)
  const { token, isLoggedIn } = useAppSelector((state) => state.currentUser)

  return (
    <div className="rounded-xl bg-white p-3 sm:p-6 md:shadow">
      <ReviewTextInput
        containerCss="max-w-full"
        eventName="title"
        prompt="Your review title"
        placeholder="Something short and sweet"
        value={reviewData.title || ''}
        setValue={(val) => {
          if (isLoggedIn) {
            updateReview(token, { title: val }, reviewData.id)
          }
          setReviewData({ ...reviewData, title: val, status: 'draft' })
        }}
        maxCharacters={80}
        showError={showError}
        required
      />
      <ReviewTextInput
        containerCss="max-w-full"
        eventName="pros"
        prompt="Pros"
        placeholder="Things you liked about the building"
        multiline
        value={reviewData.pros || ''}
        setValue={(val) => {
          if (isLoggedIn) {
            updateReview(token, { pros: val }, reviewData.id)
          }
          setReviewData({ ...reviewData, pros: val, status: 'draft' })
        }}
        showError={showError}
        required
      />
      <ReviewTextInput
        containerCss="max-w-full"
        eventName="cons"
        prompt="Cons"
        placeholder="Things you DID NOT like about the building"
        multiline
        value={reviewData.cons || ''}
        setValue={(val) => {
          if (isLoggedIn) {
            updateReview(token, { cons: val }, reviewData.id)
          }
          setReviewData({ ...reviewData, cons: val, status: 'draft' })
        }}
        showError={showError}
        required
      />
      <ReviewTextInput
        containerCss="max-w-full"
        eventName="advice_to_owner"
        prompt="Advice to the property owner"
        placeholder="What do you want to suggest to the owner?"
        value={reviewData.advice || ''}
        minWords={null}
        setValue={(val) => {
          if (isLoggedIn) {
            updateReview(token, { advice: val }, reviewData.id)
          }
          setReviewData({ ...reviewData, advice: val, status: 'draft' })
        }}
      />
    </div>
  )
}

export default CreateProsCons
