import Image from 'next/image'
import React from 'react'
import { segment } from 'src/lib/Segments'

import { Resource } from '@openigloo/common'

interface BlogCardProps {
  blog: Resource
  customCardStyle?: string
  customIconPosition?: string
  segmentBase?: string
}

const BlogCard = ({ blog, segmentBase = 'blog' }: BlogCardProps): JSX.Element => {
  return (
    <>
      <a
        key={blog.id}
        href={blog.url}
        rel="noreferrer"
        onClick={() => segment?.[segmentBase + '_resource_card']?.(blog.id)}
        className="block overflow-hidden rounded-2xl bg-white"
      >
        <div className="relative h-32">
          <Image
            className="overflow-hidden rounded-t-2xl object-left-top md:object-center"
            src={blog.image}
            alt="building"
            layout="fill"
            loading="lazy"
            unoptimized
            objectFit="cover"
          />
        </div>
        <div className="flex h-24 pt-4 font-semibold">
          <p>{blog.title}</p>
        </div>
      </a>
    </>
  )
}

export default BlogCard
