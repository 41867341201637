import Head from 'next/head'
import React from 'react'
import { jsonLdScriptProps } from 'react-schemaorg'
import {
  AggregateRating,
  Answer,
  Apartment,
  ApartmentComplex,
  CreativeWork,
  FAQPage,
  LocalBusiness,
  Question,
  WithContext,
} from 'schema-dts'
import { BuildingApi, ContactApi } from 'src/common/types'

import {
  BuildingFaqs,
  BuildingMetadata,
  OpenListing,
  Question as Q,
  Review,
} from '@openigloo/common'
import { ISODateFormat, getFirstDateOfMonth, titleCase } from '@utility/Utilities'

// import { getInstitutionalContactMeta } from './ContactMeta'

interface Props {
  building: BuildingApi
  contacts?: ContactApi[]
  listings?: OpenListing[]
  limitedReviews?: Review[]
  faqs?: BuildingFaqs
  buildingMetaData?: BuildingMetadata
  questions?: Q[]
}

export function getBuildingMeta(
  building: BuildingApi,
  metaData: BuildingMetadata
): ApartmentComplex {
  const { address } = metaData
  return {
    '@context': 'https://schema.org',
    '@type': 'ApartmentComplex',
    name: address.streetAddress,
    address: {
      '@type': 'PostalAddress',
      streetAddress: address.streetAddress,
      addressLocality: address.addressLocality,
      addressRegion: address.addressRegion,
      postalCode: address.postalCode.toString(),
    },
    numberOfAccommodationUnits: {
      '@type': 'QuantitativeValue',
      value: metaData.accommodationnits,
    },
    description: metaData.description,
    url: `https://openigloo.com/building/${building?.neighborhood?.city}/${building?.id}/${building?.primaryAddress?.slug}`,
    latitude: building.lat,
    longitude: building.lon,
  } as ApartmentComplex
}

export function getListingMeta(listing: OpenListing): Apartment {
  return {
    '@type': 'Apartment',
    '@context': 'https://schema.org',
    numberOfBedrooms: listing.noBedrooms,
    numberOfBathroomsTotal: listing.noBathrooms,
    numberOfRooms: listing.noRooms,
    name: listing.fullAddress,
    address: {
      '@type': 'PostalAddress',
      streetAddress: titleCase(listing.fullAddress || ''),
      // addressLocality: titleCase(listingObject.borough || ''),
      addressRegion: 'NY',
      // postalCode: listingObject.zipCode.toString(),
    },
  } as Apartment
}

const faqDateModified = getFirstDateOfMonth()

export const getFaqMeta = (faqs, metaData: BuildingMetadata): FAQPage => {
  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    name: `${metaData.address.streetAddress} ${metaData.address.addressRegion} public data`,
    mainEntity: faqs.map((qa) => {
      return {
        '@type': 'Question',
        name: qa?.question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: qa?.answer,
        } as Answer,
      } as Question
    }),
    dateModified: faqDateModified,
  } as FAQPage
}

const BuildingSEO = ({
  building,
  listings,
  limitedReviews,
  faqs,
  buildingMetaData,
  questions,
}: Props): JSX.Element => {
  const structuredDataBuilding = getBuildingMeta(building, buildingMetaData)
  const openListingsData = []
  if (listings && listings.length > 0) {
    listings.map((listingObject) => {
      return openListingsData.push(getListingMeta(listingObject))
    })
  }

  const ownersStructuredData = []
  // if (contacts && contacts.length > 0) {
  //   const owners = contacts.filter(
  //     (contact) => !contact.isIndividual && contact.roles.includes('Corporate Owner')
  //   )
  //   if (owners.length > 0) {
  //     ownersStructuredData = owners
  //       .filter((owner) => owner.reviewCount > 0)
  //       .map((owner) => {
  //         return getInstitutionalContactMeta(owner, [building])
  //       })
  //   }
  // }
  if (ownersStructuredData.length === 0) {
    ownersStructuredData.push({
      '@context': 'https://schema.org',
      '@type': 'LocalBusiness',
      name: titleCase(building?.primaryAddress?.fullAddress || 'Unknown'),
      address: {
        '@type': 'PostalAddress',
        streetAddress: titleCase(building?.primaryAddress?.fullAddress ?? ''),
        addressLocality: titleCase(building?.neighborhood?.borough ?? ''),
        addressRegion: 'NY',
        postalCode: building?.zipCode ?? '',
      },
      aggregateRating:
        building?.reviewCount > 0
          ? {
              worstRating: 1,
              bestRating: 5,
              ratingCount: building?.reviewCount,
              ratingValue: building?.averageRating,
              name: titleCase(building?.primaryAddress?.fullAddress || 'Unknown'),
            }
          : ({} as AggregateRating),
      image: building?.images?.[0]?.url,
      url: `https://openigloo.com/building/${building?.neighborhood?.city ?? 'nyc'}/${
        building?.id
      }/${building?.primaryAddress?.slug}`,
      description: building?.description ?? '',
    })
  }

  const reviewSEO = (review) => {
    return {
      '@context': 'https://schema.org',
      '@type': 'UserReview',
      name: review.title,
      reviewAspect: 'Buidling and building management',
      reviewBody: `Pros: ${review.pros} Cons: ${review.cons} Advice to owner: ${review.advice}`,
      reviewRating: {
        '@type': 'Rating',
        author: {
          '@type': 'Person',
          name: 'openigloo tenant',
        },
        bestRating: 5,
        worstRating: 1,
        ratingValue: review.rating,
      },
      author: {
        '@type': 'Person',
        name: 'openigloo tenant',
      },
      about: structuredDataBuilding,
      itemReviewed: ownersStructuredData,
      dateModified: ISODateFormat(review.atApproved),
    } as CreativeWork
  }

  const reviewContents = limitedReviews?.map((review) => reviewSEO(review)) || []
  let faqQnA = []
  questions?.map((d) => {
    if (d.answers.length > 0) {
      faqQnA.push({ question: d.question, answer: d.answers[0].answer })
    }
  })
  faqQnA = faqQnA.concat(faqs)
  const faqSEO = getFaqMeta(faqQnA, buildingMetaData)

  return (
    <Head>
      <script
        {...jsonLdScriptProps<ApartmentComplex>(
          structuredDataBuilding as WithContext<ApartmentComplex>
        )}
      />
      {/* Show owners data only when no reviews data */}
      {listings &&
        listings.length > 0 &&
        openListingsData.map((data, index) => {
          return (
            <script
              key={`listing-${index}`}
              {...jsonLdScriptProps<Apartment>(data as WithContext<Apartment>)}
            />
          )
        })}
      {faqQnA && faqQnA.length > 0 && (
        <script {...jsonLdScriptProps<FAQPage>(faqSEO as WithContext<FAQPage>)} />
      )}
      {reviewContents.length > 0 &&
        reviewContents.map((data, index) => {
          return (
            <script
              key={`review-content-${index}`}
              {...jsonLdScriptProps<CreativeWork>(data as WithContext<CreativeWork>)}
            />
          )
        })}
      {!(reviewContents.length > 0) &&
        ownersStructuredData.map((data, index) => {
          return (
            <script
              key={`contact-${index}`}
              {...jsonLdScriptProps<LocalBusiness>(data as WithContext<LocalBusiness>)}
            />
          )
        })}
    </Head>
  )
}

export default BuildingSEO
