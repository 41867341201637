import { useContext } from 'react'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { reviewInfo } from 'src/common/Constants'
import { updateReview } from 'src/data/Review'
import { segment } from 'src/lib/Segments'
import { APContextReview } from 'src/pages/review/[buildingSlug]'

import { useAppSelector } from '@redux/hooks'

import { StarRating } from '..'
import Tooltip from '../generic/Tooltip'

type Props = {
  showError: boolean
}

function CreateRatings({ showError }: Props) {
  const { token, isLoggedIn } = useAppSelector((state) => state.currentUser)
  const { reviewData, setReviewData } = useContext(APContextReview)

  const RatingsInfo = () => {
    return (
      <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
        {reviewInfo?.map((data, index) => {
          return (
            <div key={index}>
              <div className="text-base font-semibold text-dark-900">{data.title}</div>
              <div className="text-sm text-dark-700">{data.description}</div>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className="rounded-xl bg-white p-3 sm:p-6 md:shadow">
      <div className="text-center text-xl font-semibold text-lilac-500 sm:text-left">
        Building Ratings <span className="text-red-400">*</span>
      </div>
      <div className="relative items-center pr-2 pt-2 text-center text-dark-700 sm:flex">
        <span>How would you rate this building in the following categories?</span>
        <Tooltip
          toolTipComponent={
            <AiOutlineInfoCircle color="#253858" className="ml-2 inline-flex pt-0.5 sm:pt-0" />
          }
          toolTipContent={<RatingsInfo />}
          customCssComp="!static inline-flex"
          customCss="!bg-light-10 !p-4 !border-light-40 !-left-[1px] sm:!top-10 !top-14 sm:max-w-full max-w-[320px]"
        />
      </div>
      {reviewInfo.map((data, index) => {
        return (
          <div
            className={`mt-6 flex items-center justify-between rounded-xl border 
          bg-light-10 py-4 px-4 sm:px-14 ${
            showError && !reviewData[data.key] ? 'border-red-400' : 'border-transparent'
          }`}
            key={index}
          >
            <div className="font-semibold text-dark-700">{data.title}</div>
            <StarRating
              rating={reviewData[data.key] || 0}
              isStatic={false}
              starSize={24}
              onRatingChange={(value) => {
                if (isLoggedIn) {
                  updateReview(token, { [data.key]: value }, reviewData.id)
                }
                setReviewData({ ...reviewData, [data.key]: value, status: 'draft' })
                segment.review_ratings(data.title, value)
              }}
            />
          </div>
        )
      })}
    </div>
  )
}

export default CreateRatings
