export function shouldShowAddressSearchBar(pathname: string) {
  const urls = []
  return urls.some((url) => pathname.startsWith(url))
}

export function shouldShowFullSearchBars(pathname: string, isMobile: boolean) {
  const urlPrefixes = isMobile
    ? [
        '/listings',
        '/blogs',
        '/buildings',
        '/new',
        '/[city]',
        '/building/[city]',
        '/contact/[city]',
        '/unit/[city]',
        '/nyc-rent-calculator',
        '/subscription',
        '/',
      ]
    : [
        '/listings',
        '/blogs',
        '/buildings',
        '/[city]',
        '/building/[city]',
        '/contact/[city]',
        '/unit/[city]',
        '/nyc-rent-calculator',
        '/subscription',
        '/',
      ]
  if (pathname.includes('rent-stabilized-apartments') || pathname.includes('review')) {
    return false
  }
  return urlPrefixes.some((url) => pathname.startsWith(url))
}

export function shouldMinimiseHeader(pathname: string) {
  const urls = []
  return urls.some((url) => pathname.startsWith(url))
}

export function xlHeader(pathname: string) {
  const urls = []
  return urls.some((url) => pathname.startsWith(url))
}

export function shouldShowWriteReview(pathname: string) {
  return (
    pathname === '/' ||
    pathname === '/listings' ||
    pathname === '/building/[city]' ||
    pathname === '/contact/[city]' ||
    pathname === '/unit/[city]' ||
    pathname === '/subscription'
  )
}

export function isBuildingPage(pathname: string) {
  return pathname.startsWith('/building/')
}

export function isHomePage(pathname: string) {
  return pathname === '/'
}

export function isListingsPage(pathname: string) {
  return pathname.startsWith('/listings')
}

export function shouldShowBottomNav(pathname: string) {
  return !pathname.startsWith('/review')
}
