import React from 'react'
import { isMobile } from 'react-device-detect'
import { reviewInfo } from 'src/common/Constants'

import InfoToolTip from '@components/generic/InfoToolTip'

import { StarRating } from '..'

interface BuildingOverviewProps {
  ratings: any
  blurred?: boolean
}

const BuildingOverviewCard = ({ ratings, blurred = false }: BuildingOverviewProps): JSX.Element => {
  const RatingsInfo = () => {
    return (
      <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
        {reviewInfo?.map((data, index) => {
          return (
            <div key={index}>
              <div className="text-base font-semibold text-dark-900">{data.title}</div>
              <div className="text-sm text-dark-700">{data.description}</div>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className="text-bluegray-500 sm:inline-flex">
      <div className="mb-2 flex-col justify-between sm:w-full">
        <div className="mb-2 flex items-center space-x-2">
          <h2 className="text-xl font-semibold text-dark-900">Building Ratings</h2>
          {!blurred && (
            <InfoToolTip
              tip={<RatingsInfo />}
              id="ratings-info"
              direction={isMobile ? 'bottom' : 'right'}
              mobileResponsive="sm:!ml-4 !ml-0 sm:!w-1/2 !w-9/12"
            />
          )}
        </div>
        <div
          className={`border-graylight-500 mt-8 rounded-xl border py-5
          ${blurred ? 'blur-sm filter' : ''}`}
        >
          {reviewInfo.map((rating) => (
            <div
              className="mx-auto px-8 py-1 font-semibold sm:inline-flex sm:w-1/2 sm:justify-between"
              key={rating.aggKey}
            >
              <div className="mr-2 text-base text-dark-900">{rating.title}</div>
              <StarRating rating={ratings?.[rating.aggKey] ?? null} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default BuildingOverviewCard
