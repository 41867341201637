import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai'
import BeatLoader from 'react-spinners/BeatLoader'
import { ReviewApi } from 'src/common/types'

import OIIcon from '@components/icons'
import { ActionButton } from '@components/index'
import NewReviewCard from '@components/review/NewReviewCard'
import { BuildingAggResponse, Review } from '@openigloo/common'
import { useAppSelector } from '@redux/hooks'

import { BuildingOverviewCard, ResponseProgress, Section, Separator } from '..'

type BuildingReviewDataProps = {
  reviews: ReviewApi[] | Partial<Review>[]
  responses: BuildingAggResponse
  blurred?: boolean
  isFetching?: boolean
  segmentBase?: string
  reviewFilter?: JSX.Element
  reviewTitle?: string
  isFetchingMore?: boolean
  reviewCount?: number
  ratings?: any
}

export default function BuildingReviewData({
  responses,
  reviews = [],
  blurred = false,
  isFetching = false,
  segmentBase = 'other',
  reviewFilter = null,
  reviewTitle = null,
  isFetchingMore = false,
  reviewCount = null,
  ratings = null,
}: BuildingReviewDataProps) {
  const router = useRouter()
  const { isLoggedIn, explorerData, reviewerData } = useAppSelector((state) => state.currentUser)
  const [showMore, setShowMore] = useState(false)
  const [visibleReviews, setVisibleReviews] = useState(reviews.slice(0, 10))

  useEffect(() => {
    if (router.isReady && router.asPath.includes('#reviews')) {
      const ele = document.querySelector('#reviews')
      if (ele) {
        ele.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [])

  const handleToggle = () => {
    if (showMore) {
      setVisibleReviews(reviews.slice(0, 10))
    } else {
      setVisibleReviews(reviews.slice(0, 20))
    }
    setShowMore(!showMore)
  }

  return (
    <Section customStyle={`${blurred ? 'select-none' : ''}`}>
      <div className="flex-col justify-between lg:w-5/6">
        <BuildingOverviewCard ratings={ratings} blurred={blurred} />
      </div>

      {responses && (
        <>
          <Separator customStyle="my-8 !pt-0" />
          <h2 className="mb-8 text-xl font-semibold text-dark-900">Renter Recommendations</h2>
          <Section
            customStyle={`grid grid-rows-2 gap-6 md:grid-rows-1 md:grid-cols-2 lg:w-5/6 relative w-full px-1 ${
              blurred ? 'filter blur-sm' : ''
            }`}
          >
            <div className="flex items-center rounded-xl bg-white p-4 shadow-md sm:p-0">
              <ResponseProgress
                value={responses.recommendPerc}
                text=""
                icon={
                  <OIIcon
                    name="newBuilding"
                    size="custom"
                    height={31}
                    width={31}
                    className="text-bluegray-500"
                  />
                }
                showPercentInside={false}
              />
              <div className="px-6 text-bluegray-500 sm:pl-1">
                <span className="text-2xl font-semibold">
                  {responses.recommendPerc == null ? '--' : responses.recommendPerc}%
                </span>
                <span> of renters recommend this building</span>
              </div>
            </div>

            <div className="flex items-center rounded-xl bg-white p-4 shadow-md sm:p-0">
              <ResponseProgress
                value={responses.approvePerc}
                text=""
                icon={
                  <OIIcon
                    name="halfCheckUser"
                    size="custom"
                    height={31}
                    width={34}
                    className="text-bluegray-500"
                  />
                }
                showPercentInside={false}
              />
              <div className="px-6 text-bluegray-500 sm:pl-1">
                <span className="text-2xl font-semibold">
                  {responses.approvePerc == null ? '--' : responses.approvePerc}%
                </span>
                <span> of renters approve of this owner</span>
              </div>
            </div>
          </Section>
          <Separator customStyle="my-8" />
          <h2 className="text-xl font-semibold text-dark-900">Rents and Deposits</h2>
          <Section customStyle={`lg:w-5/6 ${blurred ? 'filter blur-sm' : ''} my-8 !pt-0`}>
            <div className="relative mb-4 grid w-full grid-rows-2 gap-6 md:grid-cols-2 md:grid-rows-1">
              <div className="flex items-center rounded-xl bg-white px-6 py-10 shadow-md">
                <div className="px-6 text-bluegray-500 sm:pl-1">
                  <span className="text-2xl font-semibold">
                    {responses.recDepositPerc !== null ? responses.recDepositPerc + '%' : '--'}
                  </span>
                  <span> of renters received their security deposits back</span>
                </div>
                <div>
                  <OIIcon
                    name="dollarMoney"
                    size="custom"
                    height={38}
                    width={52}
                    className="text-bluegray-300"
                  />
                </div>
              </div>
              <div className="flex items-center rounded-xl bg-white px-6 py-10 shadow-md">
                <div className="px-6 text-bluegray-500 sm:pl-1">
                  It{' '}
                  {responses.numElectronicPaymentReviews ? (
                    <>
                      <span className="text-2xl font-semibold">HAS </span>
                      {responses?.numElectronicPayment ? (
                        <span>
                          been reported that this building accepts electronic rent payments.
                        </span>
                      ) : (
                        <>
                          <span>been reported that this building does</span>{' '}
                          <span className="text-2xl font-semibold">NOT</span>{' '}
                          <span>accept electronic rent payments.</span>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <span className="text-2xl font-semibold">HAS NOT </span>
                      <span>been reported if this building accepts electronic rent payments.</span>
                    </>
                  )}
                </div>
                <div>
                  <OIIcon
                    name="creditcard"
                    size="custom"
                    height={42}
                    width={52}
                    className="text-bluegray-300"
                  />
                </div>
              </div>
            </div>
          </Section>
          <Separator customStyle="!mb-0" />
        </>
      )}

      <div
        className="my-8 items-center justify-between space-y-4 sm:flex sm:space-y-0"
        id="reviews"
      >
        <h2 className="text-xl font-semibold text-dark-900">
          {reviewTitle ? reviewTitle : 'Reviews'} (
          {reviewCount ? reviewCount : reviews?.length ?? 0})
        </h2>
        {reviewFilter && reviews.length > 0 && isLoggedIn && !blurred && (
          <div className="flex justify-end">{reviewFilter}</div>
        )}
      </div>
      {!blurred && (
        <Section customStyle="!pt-0">
          {isFetching ? (
            <BeatLoader />
          ) : (
            <div className="relative -mx-2 mb-5">
              {reviews?.map((rev, index) => {
                return (
                  <div
                    className="reviewcard w-full px-2 sm:w-5/6"
                    key={`${rev.id}-${index}`}
                    id={rev.id}
                  >
                    <NewReviewCard
                      showReviewActions={
                        isLoggedIn &&
                        (reviewerData?.hasReviewerAccess || explorerData?.accessType !== null)
                      }
                      review={rev}
                      segmentBase={segmentBase}
                      blurred={blurred}
                      showEditOptions={false}
                    />
                  </div>
                )
              })}
              {isFetchingMore && <BeatLoader color="#C1C7D0" size={10} />}
              {reviews?.length === 0 && !blurred && (
                <span className="mx-auto w-full px-2 text-sm font-semibold text-mid-80">
                  No reviews yet
                </span>
              )}
            </div>
          )}
        </Section>
      )}
      {blurred && (
        <Section customStyle="!pt-0">
          <div className="relative -mx-2 mb-5">
            {visibleReviews?.map((rev, index) => {
              return (
                <div
                  className="reviewcard w-full px-2 sm:w-5/6"
                  key={`${rev.id}-${index}`}
                  id={rev.id}
                >
                  <NewReviewCard
                    showReviewActions={
                      isLoggedIn &&
                      (reviewerData?.hasReviewerAccess || explorerData?.accessType !== null)
                    }
                    review={rev}
                    segmentBase={segmentBase}
                    blurred={blurred}
                    showEditOptions={false}
                  />
                </div>
              )
            })}
            {reviews.length > 10 && (
              <div className={`mt-4 mb-8 flex justify-center`}>
                <ActionButton
                  label={
                    <div className="flex items-center space-x-2">
                      <div>{showMore ? 'Show less reviews' : 'Show more reviews'}</div>
                      {showMore ? (
                        <AiOutlineMinus color="#061938" size={16} />
                      ) : (
                        <AiOutlinePlus color="#061938" size={16} />
                      )}
                    </div>
                  }
                  customStyle="text-sm rounded-full bg-white border border-light-40 !text-dark-900"
                  style="none"
                  onClick={handleToggle}
                />
              </div>
            )}
            {visibleReviews?.length === 0 && (
              <span className="mx-auto w-full px-2 text-sm font-semibold text-mid-80">
                No reviews yet
              </span>
            )}
          </div>
        </Section>
      )}
    </Section>
  )
}
