import Image from 'next/image'
import { UnitApi } from 'src/common/types'

import dishwasherIcon from '@public/dishwasher.svg'
import doormanIcon from '@public/doorman.svg'
import elevatorIcon from '@public/elevator.svg'
import gymIcon from '@public/gym.svg'
import laundryIcon from '@public/laundry.svg'
import outdoorIcon from '@public/outdoor.svg'
import parkingIcon from '@public/parking.svg'
import petsIcon from '@public/pets.svg'
import poolIcon from '@public/pool.svg'
import washerIcon from '@public/washer.svg'
import { titleCase } from '@utility/Utilities'

const amenityArr = [
  {
    keyName: 'isUnitPetsAllowed',
    icon: petsIcon,
    name: 'Pets Allowed',
  },
  {
    keyName: 'isUnitWasherDryer',
    icon: washerIcon,
    name: 'Washer / Dryer',
  },
  {
    keyName: 'isUnitPrivateOutdoor',
    icon: outdoorIcon,
    name: 'Private Outdoor',
  },
  {
    keyName: 'isUnitDishwasher',
    icon: dishwasherIcon,
    name: 'Dishwasher',
  },
  {
    keyName: 'isBuildingOutdoor',
    icon: outdoorIcon,
    name: 'Outdoor Space',
  },
  {
    keyName: 'isBuildingGym',
    icon: gymIcon,
    name: 'Gym',
  },
  {
    keyName: 'isBuildingPool',
    icon: poolIcon,
    name: 'Pool',
  },
  {
    keyName: 'isBuildingParking',
    icon: parkingIcon,
    name: 'Parking',
  },
  {
    keyName: 'isBuildingDoorman',
    icon: doormanIcon,
    name: 'Doorman',
  },
  {
    keyName: 'isBuildingLaundryRoom',
    icon: laundryIcon,
    name: 'Laundry Room',
  },
  {
    keyName: 'isBuildingElevator',
    icon: elevatorIcon,
    name: 'Elevator',
  },
]

const AmenityIcon = ({ icon, name }) => {
  return (
    <div className="mb-3 flex w-48 items-center">
      <div className={`mx-auto mr-2 rounded-md p-2`}>
        <div className={`relative h-8 w-8`}>
          <Image src={icon} alt={name} layout="fill" unoptimized />
        </div>
      </div>
      <span className={`flex-1 text-base text-dark-900`}>{titleCase(name)}</span>
    </div>
  )
}

const UnitAmenities = ({ listing }: { listing: UnitApi }) => {
  const hasAmenities = () => {
    return amenityArr.some((amenity) => listing[amenity.keyName] === true)
  }

  return (
    <>
      <div className="text-xl font-semibold">Amenities</div>
      <div>
        {hasAmenities() ? (
          <>
            <div>
              <div className="py-8 pb-4 text-xl text-dark-700">Building amenities</div>
              <div className="flex w-full flex-row flex-wrap sm:w-4/5">
                {amenityArr.map((amenity) => {
                  return (
                    amenity.keyName.includes('isBuilding') &&
                    listing[amenity.keyName] && (
                      <div key={amenity.keyName}>
                        <AmenityIcon icon={amenity.icon} name={amenity.name} />
                      </div>
                    )
                  )
                })}
              </div>
            </div>
            <div>
              <div className="py-8 pb-4 text-xl text-dark-700">Unit amenities</div>
              <div className="flex w-full flex-row flex-wrap sm:w-4/5">
                {amenityArr.map((amenity) => {
                  return (
                    amenity.keyName.includes('isUnit') &&
                    listing[amenity.keyName] && (
                      <div key={amenity.keyName}>
                        <AmenityIcon icon={amenity.icon} name={amenity.name} />
                      </div>
                    )
                  )
                })}
              </div>
            </div>
          </>
        ) : (
          <div className="pt-3 text-dark-900">No Amenities Listed</div>
        )}
      </div>
    </>
  )
}

export default UnitAmenities
