import React from 'react'

const cleanText = (text) => text.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '').toLowerCase()

const HighlightText = ({ text, searchTerm }) => {
  if (!searchTerm) {
    return <span>{text}</span>
  }
  const cleanedSearchTerm = cleanText(searchTerm)

  const result: any = []
  let matchIndex = 0 // Tracks the index in the search term

  for (let i = 0; i < text.length; i++) {
    const originalChar = text[i]
    const cleanedChar = cleanText(originalChar)

    if (matchIndex < cleanedSearchTerm.length && cleanedChar === cleanedSearchTerm[matchIndex]) {
      // Matching part
      result.push({ text: originalChar, isMatch: true })
      matchIndex++
    } else {
      // Non-matching part
      result.push({ text: originalChar, isMatch: false })
    }
  }

  return (
    <span>
      {result.map((part, index) => (
        <span key={index} className={`${!part.isMatch ? 'font-semibold' : ''}`}>
          {part.text}
        </span>
      ))}
    </span>
  )
}

export default HighlightText
