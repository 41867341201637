import React, { useEffect, useState } from 'react'
import { IoInformationCircleOutline } from 'react-icons/io5'
import ReactTooltip from 'react-tooltip'

type props = {
  mobileResponsive?: string
  id: string
  tip: any
  direction: any
  onShow?: () => void
  customTop?: number
  customColor?: string
}

export default function InfoToolTip({
  id,
  tip,
  direction,
  mobileResponsive = '',
  onShow = null,
  customTop = 0,
  customColor = 'text-dark-900',
}: props) {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true) // Wait until the component has fully mounted
  }, [])

  function overrideTipPosition({ left, top }, event, triggerElement, tooltipElement) {
    const arrowLeft = triggerElement.getBoundingClientRect().left + triggerElement.offsetWidth / 2
    const arrowTop =
      triggerElement.getBoundingClientRect().top + triggerElement.offsetHeight + 6 - customTop
    const sheet = document.createElement('style')

    sheet.innerHTML = `
      .__react_component_tooltip { pointer-events: auto !important; }
      .__react_component_tooltip.place-bottom::after {
        position: fixed;
        top: ${arrowTop}px;
        left: ${arrowLeft}px;
      }
      .__react_component_tooltip.place-bottom::before {
        position: fixed;
        top: ${arrowTop + 5}px;
        left: ${arrowLeft}px;
      }
    `
    document.body.appendChild(sheet)

    return {
      top,
      left: Math.min(left, window.innerWidth - tooltipElement.offsetWidth),
    }
  }

  return (
    <>
      <span className="cursor-pointer" data-for={id} data-tip onMouseUp={() => ReactTooltip.hide()}>
        <IoInformationCircleOutline size={16} className={customColor} color="#061938" />
      </span>
      {isMounted && (
        <ReactTooltip
          place={direction}
          type="light"
          effect="float"
          id={id}
          border={true}
          afterShow={onShow}
          borderColor="#DFE1E6"
          backgroundColor="#FAFBFC"
          event="click"
          globalEventOff="click"
          overridePosition={overrideTipPosition}
          textColor="#061938"
          className={`w-10/12 !rounded-2xl !px-3 !py-3 text-base font-medium !opacity-100 sm:w-1/2 sm:!px-4 ${mobileResponsive}`}
        >
          <div className="text-left text-sm">{tip}</div>
        </ReactTooltip>
      )}
    </>
  )
}
