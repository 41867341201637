export const EmptyPrompt = () => {
  return <p></p>
}
export const BrowsePrompt = () => <p></p>
export const OnboardingPrompt = () => (
  <p>
    Login or sign up to browse listings and get personalized notifications on apartments that meet
    your criteria.
  </p>
)
export const CreateReviewPrompt = () => (
  <p>
    Log in or sign up to submit your review. Your email will <b>never</b> be shared or associated
    with your review. We require this sign-up so we can verify that you are not a bot.
  </p>
)
export const ProfilePrompt = () => (
  <p>
    Log in or sign up to access your referrals and credits, change profile settings, and edit
    reviews.
  </p>
)
export const SaveSharePrompt = () => (
  <p>Log in or sign up to save buildings, owners, and resources.</p>
)
export const SaveTabPrompt = () => (
  <p>Log in or sign up to view your saved buildings, owners, and resources.</p>
)
export const UploadPrompt = () => (
  <p>
    Log in or sign up to upload photos to your review. You email will never be shared or associated
    with your review. We require this sign-up, so we can verify that you aren't a bot.
  </p>
)

export const ReferralPrompt = () => <p>Log in or sign up to track your rewards and redeem.</p>

export const RentalAppsPrompt = () => (
  <p>Log in or sign up to start, save and submit your rental applications.</p>
)

export const RenterQAPrompt = () => (
  <p>
    <div className="pb-2 font-bold">Log in or sign up to ask or answer a question.</div>
    Q&A is anonymous, and your email will not be connected to your responses.
  </p>
)

export const RevonaPrompt = () => <p>Get started on your rental application by signing up below.</p>

export const AuthPrompts = {
  empty: EmptyPrompt,
  browse: BrowsePrompt,
  createReview: CreateReviewPrompt,
  profile: ProfilePrompt,
  save: SaveSharePrompt,
  saved: SaveSharePrompt,
  upload: UploadPrompt,
  referral: ReferralPrompt,
  default: BrowsePrompt,
  rental: RentalAppsPrompt,
  renterQA: RenterQAPrompt,
  onboarding: OnboardingPrompt,
  revona: RevonaPrompt,
}

export const getAuthPrompt = (name: keyof typeof AuthPrompts) => {
  return AuthPrompts[name] || BrowsePrompt
}
