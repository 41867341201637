import { segment } from 'src/lib/Segments'

import ActionButton from '@components/buttons/actionButton'

type props = {
  setShowOfflinePopup: (v: boolean) => void
}

function NetworkDisconnect({ setShowOfflinePopup }: props) {
  return (
    <div
      className="fixed inset-0 z-[100] w-full overflow-hidden overflow-y-scroll bg-black bg-opacity-90 text-black"
      id="background"
      onClick={() => setShowOfflinePopup(false)}
    >
      <div
        className="z-50 mx-auto my-32 w-11/12 overflow-hidden rounded-2xl bg-white p-4 sm:max-h-[calc(100vh-76px)] lg:w-4/12"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="text-center">
          <div className="font-semibold text-dark-900">No internet connection</div>
          <div className="pt-2 pb-4 text-sm text-dark-700">
            You can continue your review while offline, but you'll need a network connection to
            submit or save as a draft.
          </div>
          <>
            <ActionButton
              label={'Okay'}
              size="none"
              customStyle="text-sm"
              onClick={() => {
                segment?.no_internet()
                setShowOfflinePopup(false)
              }}
            />
          </>
        </div>
      </div>
    </div>
  )
}

export default NetworkDisconnect
