import { fetchSignInMethodsForEmail } from 'firebase/auth'
import { useEffect, useState } from 'react'
import { segment } from 'src/lib/Segments'
import isEmail from 'validator/lib/isEmail'

import { OIButton, OIButtonIconSize, OIButtonType, OIInput } from '@components/UI/atoms'

// import { useAppDispatch } from '@redux/hooks'
import { SectionSubheader } from '..'
import { getFirebaseAuth } from '../../lib/firebase'

const auth = getFirebaseAuth()

type LoginOrSignupProps = {
  emailPrefill?: string
  onLogin: (provider: 'apple' | 'google' | 'email', email?: string, isNewUser?: boolean) => void
}

export default function LoginOrSignup({ emailPrefill = '', onLogin }: LoginOrSignupProps) {
  useEffect(() => {
    setIsChecking(false)
  }, [])

  const [email, setEmail] = useState(emailPrefill)
  const [isChecking, setIsChecking] = useState(false)

  const onLoginViaEmail = async (email) => {
    setIsChecking(true)
    const results = await fetchSignInMethodsForEmail(auth, email)
    if (results.length === 0) {
      onLogin('email', email, true)
    } else if (results.includes('password')) {
      onLogin('email', email)
    } else {
      alert(`Email exists! Please log in via: ${results.join(', ')}`)
      setIsChecking(false)
    }
  }

  return (
    <div className="flex-1">
      <div className="my-6 space-y-4">
        <OIButton
          iconSize={OIButtonIconSize.LARGE}
          style="w-full text-dark-600 !text-sm"
          icon={'google'}
          onClick={() => onLogin('google')}
          type={OIButtonType.TERTIARY}
          label={`Continue with Google`}
        />
        <OIButton
          iconSize={OIButtonIconSize.LARGE}
          style="w-full text-dark-600 !text-sm"
          icon={'apple'}
          onClick={() => onLogin('apple')}
          type={OIButtonType.TERTIARY}
          label={`Continue with Apple`}
        />
      </div>
      <div className="flex items-center space-x-2 text-sm text-dark-500">
        <hr className="flex-1" />
        <span>or continue with email</span>
        <hr className="flex-1" />
      </div>
      <div className="my-6 flex flex-col space-y-1">
        <SectionSubheader>Your email</SectionSubheader>
        <OIInput
          className="text-sm"
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Your email"
          value={email}
          autoComplete="on"
          name="email"
        />
      </div>
      {isEmail(email) ? (
        <OIButton
          isLoading={isChecking}
          onClick={() => {
            segment.login_signup_email()
            onLoginViaEmail(email)
          }}
          disabled={!isEmail(email)}
          label="Continue"
          style="w-full text-sm"
        />
      ) : null}
    </div>
  )
}
