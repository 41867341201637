import { AuthErrorCodes, signInWithEmailAndPassword } from 'firebase/auth'
import { useState } from 'react'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { resetPasswordEmail } from 'src/data/newUserProfile'
import { segment } from 'src/lib/Segments'
import { getFirebaseAuth } from 'src/lib/firebase'

import { OIButton, OIInput } from '@components/UI/atoms'
import { setCurrentUserProfile } from '@redux/actions'
import { useThunkDispatch } from '@redux/hooks'

import { SectionSubheader } from '..'

interface LoginProps {
  email: string
  onError: (error: string) => void
}

const Login = ({ email, onError }: LoginProps) => {
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showResetPasswordMessage, setShowResetPasswordMessage] = useState(false)

  const dispatch = useThunkDispatch()

  const handleLogin = async () => {
    try {
      const result = await signInWithEmailAndPassword(getFirebaseAuth(), email, password)
      const token = await result.user.getIdToken()
      await dispatch(setCurrentUserProfile(token))
      segment.login_signup_signinbutton(email)
    } catch (e) {
      if (e.code === AuthErrorCodes.INVALID_PASSWORD) {
        onError('Invalid password!')
      } else {
        onError('Something went wrong!')
      }
    } finally {
      setIsSubmitting(false)
      setPassword('')
    }
  }

  return (
    <div className="slide-left">
      <div className="pt-4 text-xl font-semibold text-dark-900">Log in</div>
      <div className="my-6 flex flex-col space-y-1">
        <SectionSubheader style="!text-sm">Your email</SectionSubheader>
        <OIInput className="text-sm" onChange={() => null} placeholder="Your email" value={email} />
      </div>
      <div className="my-6 flex flex-col space-y-1">
        <SectionSubheader style="!text-sm -mb-4">Password</SectionSubheader>
        <div className="relative w-full">
          {showPassword ? (
            <AiOutlineEyeInvisible
              size={17}
              className="absolute right-4 top-1/2 z-10 -translate-y-1/2 text-bluegray-300"
              onClick={() => setShowPassword(!showPassword)}
            />
          ) : (
            <AiOutlineEye
              size={17}
              className="absolute right-4 top-1/2 z-10 -translate-y-1/2 text-bluegray-300"
              onClick={() => setShowPassword(!showPassword)}
            />
          )}

          <OIInput
            className="text-sm"
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter your password"
            value={password}
            type={showPassword ? 'text' : 'password'}
          />
        </div>
        <button
          disabled={showResetPasswordMessage}
          onClick={async () => {
            setPassword('')
            const data = {
              email: email,
            }
            await resetPasswordEmail(data)
            segment.login_signup_reset_password()
            setShowResetPasswordMessage(true)
            setTimeout(() => {
              setShowResetPasswordMessage(false)
            }, 10000)
          }}
          className="w-fit py-0.5 text-left text-sm text-bluegray-300 disabled:opacity-20"
        >
          Reset password
        </button>
      </div>
      <OIButton
        onClick={() => {
          setIsSubmitting(true)
          handleLogin()
        }}
        disabled={!password}
        isLoading={isSubmitting}
        style="!w-full"
        label="Log in"
      />
      {showResetPasswordMessage ? (
        <p className="my-2 text-sm leading-5 text-bluegray-300">
          Email has been sent! Please check your inbox for password reset instructions.
        </p>
      ) : null}
    </div>
  )
}

export default Login
