import Image from 'next/image'
import { useRouter } from 'next/router'
import { memo, useEffect, useRef, useState } from 'react'
import BeatLoader from 'react-spinners/BeatLoader'
import { getPageName } from 'src/common/Constants'
import { AutocompleteSuggestion } from 'src/common/types'
import {
  getSearchAutoCompleteApi,
  getSearchItemApi,
  getSearchNycAutoCompleteApi,
} from 'src/data/Buildings'
import { segment } from 'src/lib/Segments'
import useOnClickOutside from 'use-onclickoutside'
import { v4 as uuidv4 } from 'uuid'

import { isBuildingPage } from '@components/layouts/Nav/utils'
import { City, splitAddress } from '@openigloo/common'
import clock from '@public/clock.svg'
import search from '@public/search.svg'
import { AiOutlineSearch } from '@react-icons/all-files/ai/AiOutlineSearch'
import { GrFormClose } from '@react-icons/all-files/gr/GrFormClose'
import { useAppSelector } from '@redux/hooks'
import { titleCase } from '@utility/Utilities'

import HighlightText from './HighlightText'

type SearchBarProps = {
  placeholder?: string
  initValue?: string
  onClose?: () => void
  onSuggestionSelected?: (item: AutocompleteSuggestion) => void
  saveSearchHistory?: boolean
  customStyles?: string
  currentCity?: City
  segmentBase?: string
  isSearchDisabled?: boolean
  addressSelected?: string | null
  isInIframe?: boolean
}

const SearchBar = ({
  onSuggestionSelected,
  onClose = null,
  placeholder,
  customStyles,
  currentCity,
  isSearchDisabled = false,
  saveSearchHistory = true,
  addressSelected = 'dummy',
  segmentBase = 'other',
  isInIframe = false,
}: SearchBarProps) => {
  const { newbuilding } = useAppSelector((state) => state.currentBuilding)
  const router = useRouter()
  const [selectedOption, setSelectedOption] = useState(-1)
  const [showOptions, setShowOptions] = useState(false)
  const [value, setValue] = useState('')
  const [isSearching, setIsSearching] = useState(false)
  const [suggestions, setSuggestions] = useState<(AutocompleteSuggestion | null)[]>([])
  const [, setActiveRequests] = useState(0)
  const [searchToken] = useState(uuidv4())

  const ref = useRef(null)
  const inputRef = useRef(null)

  useOnClickOutside(ref, () => {
    setShowOptions(false)
  })

  useEffect(() => {
    if (searchToken) {
      if (value.length > 3) {
        setIsSearching(true)
        const timeoutId = setTimeout(() => {
          incrementActiveRequests()
          getAutoComplete(value)
        }, 1000)

        return () => clearTimeout(timeoutId)
      }
    }
  }, [value, searchToken])

  function incrementActiveRequests() {
    setActiveRequests((prev) => prev + 1)
  }

  function decrementActiveRequests() {
    setActiveRequests((prev) => {
      const updated = prev - 1
      if (updated === 0) {
        setIsSearching(false)
      }
      return updated
    })
  }

  async function getAutoComplete(value: string) {
    try {
      if (currentCity?.id == 'nyc') {
        const data = await getSearchNycAutoCompleteApi(value, searchToken)
        setSuggestions(data)
      } else {
        const data = await getSearchAutoCompleteApi(value, searchToken)
        setSuggestions(data)
      }
    } catch {
      console.log('error')
      setSuggestions([])
    } finally {
      decrementActiveRequests()
    }
  }

  useEffect(() => {
    if (router.isReady && newbuilding) {
      if (isBuildingPage(router.pathname)) {
        setValue(titleCase(newbuilding.primaryAddress.fullAddress))
      }
    }
  }, [router.isReady, newbuilding])

  useEffect(() => {
    if (!addressSelected) {
      setValue('')
    }
  }, [addressSelected])

  const handleKeyDown = (e) => {
    if (!suggestions?.length) {
      return
    }
    if (e.keyCode === 40) {
      e.preventDefault()
      setSelectedOption((selectedOption + 1) % suggestions.length)
    } else if (e.keyCode === 38) {
      e.preventDefault()
      if (selectedOption > 0) {
        setSelectedOption(selectedOption - 1)
      } else {
        setSelectedOption(suggestions.length - 1)
      }
    } else if (e.keyCode === 13) {
      onSearchItemClick(suggestions[selectedOption])
    }
  }

  const onSearchItemClick = async (item: AutocompleteSuggestion) => {
    let data = item
    if (!item?.id) {
      data = await getSearchItemApi(item.placeId, searchToken)
    }

    segment?.[segmentBase + '_address_search_select']?.(data.buildingSlug, data.slug ?? '')
    segment?.header_address_search_select(getPageName(router.asPath.split('/')[1]))
    if (onSuggestionSelected) {
      onSuggestionSelected(data)
    } else if (data?.buildingSlug && data?.slug) {
      router.push(
        {
          pathname: `/building/${data?.cityId ?? 'nyc'}/${data?.buildingSlug}/${data?.slug}`,
        },
        undefined,
        { shallow: router.pathname.includes('/building/') ? true : false }
      )
    }

    if (saveSearchHistory && item && Object.prototype.hasOwnProperty.call(item, 'address')) {
      let recentSearch = JSON?.parse?.(localStorage?.getItem?.('recentSearch')) ?? []
      if (recentSearch?.length === 8) {
        recentSearch?.shift()
      }
      recentSearch = recentSearch.filter(function (obj) {
        return obj.placeId !== item.placeId
      })
      recentSearch.push(item)

      localStorage.setItem('recentSearch', JSON.stringify(recentSearch))
    }
  }

  function getRecentSearch() {
    if (
      localStorage?.getItem?.('recentSearch')?.length > 0 &&
      JSON.parse(localStorage?.getItem?.('recentSearch')).find((x) => x?.cityId === currentCity?.id)
    ) {
      return true
    }
    return false
  }

  return (
    <div
      ref={ref}
      className={`mb-2 h-10 w-full border bg-white text-sm text-black shadow-sm ${
        (suggestions.length > 0 || (saveSearchHistory && getRecentSearch())) && showOptions
          ? 'rounded-t-2xl border-none shadow-md'
          : 'rounded-3xl'
      } ${customStyles}`}
    >
      <div className="flex h-full w-full flex-row overflow-hidden rounded-full">
        <div
          className={`flex h-full w-full flex-row items-center justify-items-center rounded py-2 px-3 ${
            isSearchDisabled ? `bg-light-20/50 text-dark-900/60` : ''
          }`}
        >
          <AiOutlineSearch size={25} color="grey" />
          <input
            disabled={isSearchDisabled}
            ref={inputRef}
            className="inline-block w-11/12 bg-transparent px-1 text-dark-900 focus:outline-none"
            onKeyDown={handleKeyDown}
            onChange={(e) => {
              setShowOptions(true)
              setValue(titleCase(e.target.value) || '')
            }}
            onFocus={(e) => {
              segment?.[segmentBase + '_address_search']?.()
              setValue(titleCase(e.target.value))
              setShowOptions(true)
            }}
            placeholder={placeholder ? placeholder : 'Search for an address'}
            value={value}
          />
          <div className="ml-auto flex w-8 items-center justify-items-center">
            {isSearching ? (
              <BeatLoader size={3} color="#000000" />
            ) : (
              value && (
                <button
                  aria-label="clear selection"
                  onClick={() => {
                    if (onClose) {
                      onClose()
                    }
                    setValue('')
                    if (onSuggestionSelected) {
                      onSuggestionSelected(null)
                    }
                  }}
                >
                  <GrFormClose size={20} />
                </button>
              )
            )}
          </div>
        </div>
      </div>
      {(suggestions.length > 0 || getRecentSearch()) && showOptions ? (
        <ul
          className={`${
            isInIframe
              ? 'search-dropdown'
              : 'relative z-50 w-full overflow-hidden rounded-b-xl border-t-0 bg-white shadow-md'
          } `}
        >
          {suggestions.length > 0 ? (
            <div className="px-5 py-2 text-left font-semibold">Search results</div>
          ) : (
            <>
              {!isSearching && value?.length > 3 && (
                <div className="relative block h-auto px-5 py-2 text-left font-semibold text-dark-900/80">
                  No buildings found!
                </div>
              )}
            </>
          )}
          {suggestions
            .filter((v, i, a) => a.findIndex((v2) => v2.address === v.address) === i)
            .map((item, index) => {
              const { addressLine1, addressLine2 } = splitAddress(item?.address ?? '')
              return (
                <li
                  className={`relative flex h-auto cursor-pointer items-center px-5 py-2 text-left text-dark-900  ${
                    selectedOption === index ? 'bg-bluegray-300 !text-white' : ''
                  } hover:bg-blue-25 hover:!text-dark-900`}
                  key={item?.placeId ?? index}
                  onClick={() => {
                    if (item) {
                      onSearchItemClick(item)
                      setShowOptions(false)
                      setValue(titleCase(item.address))
                    }
                  }}
                >
                  <Image className="!pr-1.5" alt="search logo" src={search} unoptimized />{' '}
                  <HighlightText
                    text={`${titleCase(addressLine1)}, ${addressLine2}`}
                    searchTerm={value}
                  />
                </li>
              )
            })}
          {saveSearchHistory && getRecentSearch() && (
            <>
              <div className="px-5 py-2 text-left font-semibold">Recent searches</div>
              {JSON.parse(localStorage?.getItem?.('recentSearch'))
                ?.reverse()
                ?.map((item, index) => {
                  const { addressLine1, addressLine2 } = splitAddress(item?.address ?? '')
                  return (
                    currentCity?.id === item.cityId && (
                      <div
                        className={`relative flex h-auto cursor-pointer items-center px-5 py-2 text-left text-dark-900 hover:bg-blue-25`}
                        key={index}
                        onClick={() => {
                          if (item) {
                            onSearchItemClick(item)
                            setShowOptions(false)
                            setValue(titleCase(item.address))
                          }
                        }}
                      >
                        <Image className="!pr-1.5" alt="clock logo" src={clock} unoptimized />{' '}
                        {`${titleCase(addressLine1)}, ${addressLine2}`}
                      </div>
                    )
                  )
                })}
            </>
          )}
        </ul>
      ) : (
        ''
      )}
    </div>
  )
}

export default memo(SearchBar)
