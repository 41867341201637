import { GrClose } from '@react-icons/all-files/gr/GrClose'

import { ActionButton, SectionSubheader } from '..'

type ModalAction = {
  label: string
  onClick: () => void
  style?: string
}

type Props = {
  title: string
  message: string
  actions: ModalAction[]
  MessageComponent?: React.FC
  onDismiss?: () => void
  position?: 'top' | 'center'
}
export default function ActionModal({
  title,
  message,
  actions,
  onDismiss = null,
  MessageComponent = null,
  position = 'center',
}: Props) {
  return (
    <div className="fixed top-0 bottom-0 left-0 z-[100] !ml-0 h-screen w-full bg-black bg-opacity-80">
      <div
        className={`relative mx-auto h-max max-w-sm bg-white ${
          position === 'top' ? 'inset-y-1/4' : 'inset-y-1/3'
        }`}
      >
        {onDismiss !== null && (
          <GrClose
            className="absolute top-2 right-2 cursor-pointer"
            onClick={() => {
              onDismiss()
            }}
          />
        )}
        <div className="bg-gray-100 px-2 py-4 text-black">
          <SectionSubheader>{title}</SectionSubheader>
        </div>
        {message && <div className="p-4 text-black">{message}</div>}
        {MessageComponent && <MessageComponent />}
        <div className="flex flex-col items-center border-t p-4">
          {actions.map((action, ind) => {
            return (
              <ActionButton
                key={`key-${ind}`}
                label={action.label}
                onClick={action.onClick}
                style={action.style}
                size="small"
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}
