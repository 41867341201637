import React from 'react'

import CirclePercentage from '../chart/CirclePercentage'

type ResponseProgressType = {
  value?: number
  text: string
  isMasked?: boolean
  icon?: JSX.Element
  showPercentInside?: boolean
}
const ResponseProgress = ({
  value,
  text,
  icon,
  showPercentInside,
}: ResponseProgressType): JSX.Element => {
  return (
    <div className="mx-auto py-4 text-bluegray-500 sm:m-3 sm:p-4">
      <div className="mx-auto">
        <CirclePercentage
          innerText={value === null ? '--' : null}
          percent={value}
          text={text}
          icon={icon}
          showPercentInside={showPercentInside}
        />
      </div>
    </div>
  )
}

export default ResponseProgress
