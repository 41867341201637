import React from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { ArrowProps, DotProps } from 'react-multi-carousel/lib/types'

import { AiOutlineArrowLeft } from '@react-icons/all-files/ai/AiOutlineArrowLeft'
import { AiOutlineArrowRight } from '@react-icons/all-files/ai/AiOutlineArrowRight'
import { IoIosArrowBack } from '@react-icons/all-files/io/IoIosArrowBack'
import { IoIosArrowForward } from '@react-icons/all-files/io/IoIosArrowForward'

interface HorizontalScrollSectionProps {
  customContainerStyle?: string
  children: React.ReactNode
  customResponsive?: { [key: string]: any }
  showDots?: boolean
  showArrows?: boolean
  customRightArrowStyle?: string
  customLeftArrowStyle?: string
  showArrowsOutside?: boolean // You need to have a relative css to parent as arrows will come outside of carousel component
  showOutlineArrow?: boolean
  itemClass?: string
  customDotListClass?: string
  centerMode?: boolean
  autoPlay?: boolean
}

const CustomRightArrow = ({
  onClick,
  customRightArrowStyle,
  showOutlineArrow,
}: ArrowProps & { customRightArrowStyle?: string; showOutlineArrow?: boolean }) => {
  return (
    <button
      onClick={() => onClick()}
      className={`absolute right-0 rounded-full bg-white p-2 opacity-80 shadow-md focus:outline-none ${customRightArrowStyle}`}
      title="forward arrow"
    >
      {showOutlineArrow ? <AiOutlineArrowRight /> : <IoIosArrowForward />}
    </button>
  )
}
const CustomLeftArrow = ({
  onClick,
  customLeftArrowStyle,
  showOutlineArrow,
}: ArrowProps & { customLeftArrowStyle?: string; showOutlineArrow?: boolean }) => {
  return (
    <button
      onClick={() => onClick()}
      className={`absolute left-0 rounded-full bg-white p-2 opacity-80 shadow-md focus:outline-none ${customLeftArrowStyle}`}
      title="back arrow"
    >
      {showOutlineArrow ? <AiOutlineArrowLeft /> : <IoIosArrowBack />}
    </button>
  )
}

const CustomDot = (props: DotProps) => {
  const {
    carouselState: { totalItems, slidesToShow },
  } = props
  return (
    totalItems !== slidesToShow && (
      <button
        className={`mx-0.5 my-2 h-1.5 w-2.5 rounded-full border px-4 focus:outline-none ${
          props.active ? 'bg-bluegray-300' : 'bg-white'
        }`}
        {...props}
      />
    )
  )
}

const ShowArrowsOutside = ({
  next,
  previous,
  customLeftArrowStyle,
  customRightArrowStyle,
  showOutlineArrow,
  ...rest
}) => {
  const {
    carouselState: { currentSlide, totalItems, slidesToShow },
  } = rest
  const showLeft = currentSlide !== 0
  const showright = currentSlide + slidesToShow < totalItems
  return (
    <div className="carousel-button-group absolute top-1/2 w-full -translate-y-1/2">
      {showLeft && (
        <CustomLeftArrow
          onClick={previous}
          customLeftArrowStyle={`xl:-left-10 -top-5 
          ${customLeftArrowStyle}`}
          showOutlineArrow={showOutlineArrow}
        />
      )}
      {showright && (
        <CustomRightArrow
          onClick={next}
          customRightArrowStyle={`xl:-right-10 -top-5 
          ${customRightArrowStyle}`}
          showOutlineArrow={showOutlineArrow}
        />
      )}
    </div>
  )
}

const HorizontalScrollSection = ({
  children,
  customContainerStyle,
  customResponsive = null,
  showDots = false,
  showArrows = true,
  customLeftArrowStyle,
  customRightArrowStyle,
  showArrowsOutside = false,
  showOutlineArrow = false,
  itemClass = '',
  customDotListClass = '',
  centerMode = false,
  autoPlay = false,
}: HorizontalScrollSectionProps): JSX.Element => {
  const responsive =
    customResponsive !== null
      ? customResponsive
      : {
          superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 3,
            slidesToSlide: 3,
          },
          desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 3,
          },
          tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 3,
            slidesToSlide: 3,
          },
          bigMobile: {
            breakpoint: { max: 768, min: 640 },
            items: 2,
            slidesToSlide: 2,
          },
          mobile: {
            breakpoint: { max: 640, min: 0 },
            items: 1,
            slidesToSlide: 1,
            partialVisibilityGutter: 20,
          },
        }

  return (
    <Carousel
      showDots={showDots}
      itemClass={itemClass}
      arrows={showArrows && !showArrowsOutside}
      responsive={responsive}
      draggable={true}
      partialVisible={false}
      swipeable={true}
      centerMode={centerMode}
      customRightArrow={
        <CustomRightArrow
          customRightArrowStyle={customRightArrowStyle}
          showOutlineArrow={showOutlineArrow}
        />
      }
      customLeftArrow={
        <CustomLeftArrow
          customLeftArrowStyle={customLeftArrowStyle}
          showOutlineArrow={showOutlineArrow}
        />
      }
      containerClass={`${customContainerStyle}`}
      renderDotsOutside={true}
      customDot={<CustomDot />}
      autoPlay={autoPlay}
      shouldResetAutoplay={false}
      dotListClass={`!relative ${customDotListClass}`}
      renderButtonGroupOutside={true}
      customButtonGroup={
        showArrowsOutside && (
          <ShowArrowsOutside
            next={undefined}
            previous={undefined}
            customLeftArrowStyle={customLeftArrowStyle}
            customRightArrowStyle={customRightArrowStyle}
            showOutlineArrow={showOutlineArrow}
          />
        )
      }
    >
      {children}
    </Carousel>
  )
}

export default HorizontalScrollSection
