import { useRouter } from 'next/router'
import { useEffect } from 'react'

declare const _chatlio: any

export default function Chatlio() {
  let theScript
  const router = useRouter()

  useEffect(() => {
    // adding a script tag
    const head = document?.getElementsByTagName('head')?.[0]

    theScript = document?.createElement('script')
    theScript.src = '/chatlio-standard.js'
    theScript.id = 'chatlio-standard-js'

    head?.appendChild(theScript)
    document?.addEventListener('chatlio.ready', function (_e) {
      _chatlio.show({ expanded: false })
    })
  }, [router])

  useEffect(() => {
    return () => {
      theScript?.parentNode?.removeChild(theScript)
      const element = document.getElementById('chatlio-widget')
      element?.parentNode.removeChild(element)
    }
  }, [router])

  return null
}
