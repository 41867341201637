import React, { useEffect, useState } from 'react'
import { segment } from 'src/lib/Segments'

import { IoIosClose } from '@react-icons/all-files/io/IoIosClose'

import MainSliderImage from './MainSliderImage'
import SubSliderImage from './SubSliderImage'

type NewImageCarouselProps = {
  images: any
  hasFloorPlan?: boolean
  customWidthSubSlide?: string
  customWidthFloorPlan?: string
  showFullScreen?: boolean
  showSecondSlide?: boolean
  mainImageHeight?: string
  onClose?: () => void
  fullScreenTopDetails?: any
  mainImageObjectFit?: string
  setFullPageSafari?: React.Dispatch<React.SetStateAction<boolean>>
  segmentBase?: string
}

function NewImageCarousel({
  images,
  hasFloorPlan = false,
  onClose = null,
  showFullScreen = false,
  showSecondSlide = true,
  customWidthSubSlide = 'w-77-5',
  customWidthFloorPlan = 'w-23-5',
  mainImageHeight = 'h-465-px',
  fullScreenTopDetails = false,
  setFullPageSafari = null,
  mainImageObjectFit = 'fill',
  segmentBase = 'other',
}: NewImageCarouselProps) {
  const [nav1, setNav1] = useState(null)
  const [nav2, setNav2] = useState(null)
  const [showFullPage, setShowFullPage] = useState(showFullScreen)
  const [activateFloorPlan, setActivateFloorPlan] = useState(
    hasFloorPlan && images.length == 1 ? true : false
  )
  const containerStyle = showFullPage
    ? 'fixed bg-black bg-opacity-90 inset-0 py-10 items-center z-[100] h-screen w-screen showFullScreen'
    : ''

  function callbackImage(slide: number) {
    segment?.[segmentBase + '_unit_subimage_select']?.()
    if (slide === images.length - 1 && hasFloorPlan) {
      setActivateFloorPlan(true)
    } else {
      setActivateFloorPlan(false)
    }
  }

  useEffect(() => {
    document.body.style.overflowY = showFullPage ? 'hidden' : null
    // Safari Browser Fullscreen Scroll Bug Fix
    if (setFullPageSafari) {
      if (showFullPage) {
        setFullPageSafari(true)
      } else {
        setFullPageSafari(false)
      }
    }

    return () => {
      document.body.style.overflowY = null
    }
  }, [showFullPage])

  return (
    <div className={`slickSlider ${containerStyle}`}>
      <div className="mx-auto max-w-screen-lg">
        {showFullPage && (
          <div
            className={`w-full items-center justify-center pb-4 sm:flex sm:justify-between ${
              !fullScreenTopDetails && '!justify-end'
            }`}
          >
            {fullScreenTopDetails && <div className="w-full">{fullScreenTopDetails}</div>}
            <div className="absolute top-2.5 right-2.5 flex cursor-pointer justify-end font-extrabold text-white sm:relative sm:top-0">
              <IoIosClose
                onClick={() => {
                  if (!onClose) {
                    segment?.[segmentBase + '_image_fullscreen_close']?.()
                    setShowFullPage(false)
                    if (setFullPageSafari) {
                      setFullPageSafari(false)
                    }
                  } else {
                    onClose()
                  }
                }}
                size={40}
              />
            </div>
          </div>
        )}
        <MainSliderImage
          images={images}
          showFullPage={showFullPage}
          setShowFullPage={setShowFullPage}
          callbackImage={callbackImage}
          setNav1={setNav1}
          nav2={nav2}
          segmentBase={segmentBase}
          mainImageHeight={mainImageHeight}
          mainImageObjectFit={mainImageObjectFit}
        />
        {showSecondSlide && (
          <SubSliderImage
            images={images}
            showFullPage={showFullPage}
            activateFloorPlan={activateFloorPlan}
            hasFloorPlan={hasFloorPlan}
            customWidthSubSlide={customWidthSubSlide}
            customWidthFloorPlan={customWidthFloorPlan}
            callbackImage={callbackImage}
            setNav2={setNav2}
            setActivateFloorPlan={setActivateFloorPlan}
            nav1={nav1}
          />
        )}
      </div>
    </div>
  )
}

export default NewImageCarousel
