import React from 'react'

type SectionType = {
  customStyle?: string
  children?: React.ReactNode
  id?: string
}

export default function Section({
  children = '',
  customStyle = '',
  id = '',
}: SectionType): JSX.Element {
  return (
    <section className={`pt-2 ${customStyle}`} id={id}>
      {children}
    </section>
  )
}
