import React, { useState } from 'react'
import { ReviewApi } from 'src/common/types'
import { reportReview } from 'src/data/Review'
import { segment } from 'src/lib/Segments'

import { GrClose } from '@react-icons/all-files/gr/GrClose'
import { useAppSelector } from '@redux/hooks'

import ActionButton from '../buttons/actionButton'

interface Props {
  onClose: () => void
  review: ReviewApi
}

const ReportModal = ({ onClose, review }: Props) => {
  const { token } = useAppSelector((state) => state.currentUser)
  const [reportBody, setReportBody] = useState('')
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState('')

  return (
    <div
      className="fixed top-0 bottom-0 left-0 z-[100] !m-0 h-screen w-full bg-black bg-opacity-80 text-black"
      id="background"
    >
      <div className="mx-auto my-32 w-11/12 rounded-2xl bg-gray-50 pb-2 sm:w-1/3">
        <div className="border-b-1 inline-flex h-16 w-full items-center rounded-t-2xl border bg-white">
          <div className="my-auto inline-flex w-full justify-between px-4">
            <div className="text-md w-full text-center font-semibold">Report a review</div>
            <button className="my-1" onClick={onClose}>
              <GrClose size={16} />
            </button>
          </div>
        </div>
        {submitted && (
          <div className="p-2">
            <p className="my-2">
              Your report has been submitted for review and will be processed shortly.
            </p>
            <p className="my-2">
              We rely on renters like you to help grow openigloo! Please share openigloo with your
              friends and neighbors.
            </p>
          </div>
        )}
        {!submitted && (
          <div className="m-6">
            <div className="h-max pb-4">
              <p>Please tell us a little more about your concerns regarding this review:</p>
              <div
                className={
                  'flex w-full flex-row items-center justify-items-center rounded-xl border p-2'
                }
              >
                <textarea
                  value={reportBody}
                  placeholder="Enter your response"
                  onChange={(e) => setReportBody(e.target.value)}
                  className="w-full border-none bg-transparent px-1 focus:outline-none"
                />
              </div>
              {errMsg && <p className="pt-1 text-sm text-red-300">{errMsg}</p>}
            </div>
            <ActionButton
              label={isSubmitting ? 'Submiting...' : 'Submit'}
              size="small"
              onClick={async () => {
                segment.building_review_report_submit(review.id)
                try {
                  await reportReview(token, review.id, { reportText: reportBody })
                  setIsSubmitting(false)
                  setSubmitted(true)
                } catch (err) {
                  setErrMsg('Could not submit your report. Please try again!')
                  setIsSubmitting(false)
                  setSubmitted(false)
                }
              }}
              customStyle="w-max"
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default ReportModal
