import moment from 'moment'
import React, { useContext } from 'react'
import 'react-day-picker/lib/style.css'
import { TbCurrencyDollar } from 'react-icons/tb'
import { updateReview } from 'src/data/Review'
import { segment } from 'src/lib/Segments'
import { APContextReview } from 'src/pages/review/[buildingSlug]'
import isEmail from 'validator/lib/isEmail'
import isMobilePhone from 'validator/lib/isMobilePhone'

import CustomDatePicker from '@components/generic/CustomDatePicker'
import CustomSelect from '@components/generic/CustomSelect'
import { useAppSelector } from '@redux/hooks'
import { YYYYMMDDToDate, formatDate, formatPhoneNumber } from '@utility/Utilities'

import { SectionSubheader } from '..'
import RadioButton from './RadioButton'
import ReviewTextInput from './ReviewTextInput'

type Props = {
  showError: boolean
}

export default function CreateTenancy({ showError }: Props) {
  const { token, isLoggedIn } = useAppSelector((state) => state.currentUser)
  const { reviewData, setReviewData } = useContext(APContextReview)

  const moveInDate = reviewData.moveIn ? YYYYMMDDToDate(reviewData.moveIn) : null
  const moveOutDate = reviewData.moveOut ? YYYYMMDDToDate(reviewData.moveOut) : null

  function setEmailPhone(val: string) {
    if (val) {
      const isnum = /^[0-9-+()\s]*$/.test(val)
      if (isnum) {
        if (isLoggedIn) {
          updateReview(
            token,
            { landlordPhone: val?.length > 0 ? formatPhoneNumber(val) : null, landlordEmail: null },
            reviewData.id
          )
        }
        setReviewData({
          ...reviewData,
          landlordPhone: val?.length > 0 ? formatPhoneNumber(val) : null,
          landlordEmail: null,
          status: 'draft',
        })
      } else {
        if (isLoggedIn) {
          updateReview(
            token,
            { landlordPhone: null, landlordEmail: val?.length > 0 ? val : null },
            reviewData.id
          )
        }
        setReviewData({
          ...reviewData,
          landlordPhone: null,
          landlordEmail: val?.length > 0 ? val : null,
          status: 'draft',
        })
      }
    } else {
      if (isLoggedIn) {
        updateReview(token, { landlordPhone: null, landlordEmail: null }, reviewData.id)
      }
      setReviewData({ ...reviewData, landlordPhone: null, landlordEmail: null, status: 'draft' })
    }
  }

  return (
    <div className="rounded-xl bg-white p-3 sm:p-6 md:shadow">
      <div className="text-center text-xl font-semibold text-lilac-500 sm:text-left">
        Your Tenancy
      </div>
      <div className="pt-2 text-dark-700">
        Your tenancy will NOT be shared. We use this info to determine whether you are a former or
        current tenant.
      </div>
      <div className="pt-6">
        <SectionSubheader style="text-dark-900 font-semibold text-base" required>
          When did you move in?
        </SectionSubheader>
        <div className="mt-3 w-full">
          <CustomDatePicker
            placeholderText="Move-in date"
            selectedDate={moveInDate}
            maxDate={new Date()}
            inputStyle={`!rounded-full !h-10 px-4 border border-light-40 ${
              showError && !moveInDate && 'border-red-400'
            }`}
            onChange={(e) => {
              if (isLoggedIn) {
                updateReview(
                  token,
                  { moveIn: formatDate(e, 'YYYY-MM-DD'), moveOut: null },
                  reviewData.id
                )
              }
              setReviewData({
                ...reviewData,
                moveIn: formatDate(e, 'YYYY-MM-DD'),
                moveOut: null,
                status: 'draft',
              })

              segment.review_movein_date('month', moment(e).format('MMMM'))
              segment.review_movein_date('year', moment(e).format('YYYY'))
            }}
          />
        </div>
      </div>
      <div className="pt-6">
        <SectionSubheader style="text-dark-900 font-semibold text-base" required>
          When did you move out or when does your lease end?
        </SectionSubheader>
        <div className="mt-3 w-full">
          <CustomDatePicker
            disabled={!moveInDate}
            placeholderText="Move out date or lease ending date"
            selectedDate={moveOutDate}
            minDate={new Date(moveInDate).setDate(new Date(moveInDate).getDate() + 1)}
            inputStyle={`!rounded-full !h-10 px-4 border border-light-40 ${
              showError && !moveOutDate && moveInDate && 'border-red-400'
            }`}
            onChange={(e) => {
              if (isLoggedIn) {
                updateReview(token, { moveOut: formatDate(e, 'YYYY-MM-DD') }, reviewData.id)
              }
              setReviewData({
                ...reviewData,
                moveOut: formatDate(e, 'YYYY-MM-DD'),
                status: 'draft',
              })
              segment.review_moveout_date('month', moment(e).format('MMMM'))
              segment.review_moveout_date('year', moment(e).format('YYYY'))
            }}
          />
        </div>
      </div>
      <div className="pt-6">
        <SectionSubheader style="text-dark-900 font-semibold text-base">
          Landlord or management contacts (optional)
        </SectionSubheader>
        <div className="sm:flex sm:space-x-4">
          <ReviewTextInput
            prompt={null}
            eventName="lanlord_name"
            placeholder="Name"
            value={reviewData.landlordName || ''}
            setValue={(val) => {
              if (isLoggedIn) {
                updateReview(token, { landlordName: val }, reviewData.id)
              }
              setReviewData({ ...reviewData, landlordName: val, status: 'draft' })
            }}
            minWords={null}
          />
          <ReviewTextInput
            prompt={null}
            eventName="lanlord_phone"
            placeholder="Email or phone number"
            value={
              reviewData.landlordPhone ? reviewData.landlordPhone : reviewData.landlordEmail ?? ''
            }
            setValue={setEmailPhone}
            minWords={null}
            errorVal="Invalid Email or Phone"
            showError={
              !reviewData.landlordEmail && !reviewData.landlordPhone
                ? false
                : !isEmail(reviewData.landlordEmail ?? '') &&
                  !isMobilePhone(reviewData.landlordPhone ?? '')
            }
          />
        </div>
      </div>
      <div
        className={`mt-6 rounded-xl border border-transparent bg-light-10 p-4
        ${showError && typeof reviewData.recommendBuilding !== 'boolean' && 'border-red-400'}`}
      >
        <SectionSubheader style="text-dark-700 font-semibold text-base" required>
          Would you recommend this building to a friend?
        </SectionSubheader>
        <RadioButton
          style="mt-4"
          options={{ Yes: true, No: false }}
          eventName="recommend_building"
          segmentBase="review"
          selectedValue={reviewData.recommendBuilding}
          onSelect={(val) => {
            if (isLoggedIn) {
              updateReview(token, { recommendBuilding: val }, reviewData.id)
            }
            setReviewData({ ...reviewData, recommendBuilding: val, status: 'draft' })
          }}
        />
      </div>
      <div
        className={`mt-6 rounded-xl border border-transparent bg-light-10 p-4
        ${showError && typeof reviewData.approveOwner !== 'boolean' && 'border-red-400'}`}
      >
        <SectionSubheader style="text-dark-700 font-semibold text-base" required>
          Do you approve of this property owner?
        </SectionSubheader>
        <RadioButton
          style="mt-4"
          options={{ Yes: true, No: false }}
          eventName="approve_property_owner"
          segmentBase="review"
          selectedValue={reviewData.approveOwner}
          onSelect={(val) => {
            if (isLoggedIn) {
              updateReview(token, { approveOwner: val }, reviewData.id)
            }
            setReviewData({ ...reviewData, approveOwner: val, status: 'draft' })
          }}
        />
      </div>
      <div className="mt-6 rounded-xl bg-light-10 p-4">
        <SectionSubheader style="text-dark-700 font-semibold text-base">
          Did you receive your entire security deposit back (if applicable)?
        </SectionSubheader>
        <RadioButton
          style="mt-4"
          options={{ Yes: true, No: false }}
          selectedValue={reviewData.receivedDeposit}
          eventName="received_security_deposit"
          segmentBase="review"
          onSelect={(val) => {
            let newVal = val
            if (val == reviewData.receivedDeposit) {
              newVal = null
            }
            if (isLoggedIn) {
              updateReview(
                token,
                {
                  depositWithheld: null,
                  totalDeposit: null,
                  reasonDepositWithheld: null,
                  receivedDeposit: newVal,
                },
                reviewData.id
              )
            }
            setReviewData({
              ...reviewData,
              depositWithheld: null,
              totalDeposit: null,
              reasonDepositWithheld: null,
              receivedDeposit: newVal,
              status: 'draft',
            })
          }}
        />
        {reviewData.receivedDeposit == false && (
          <div>
            <ReviewTextInput
              containerCss="max-w-full mt-4"
              customCss="pl-8"
              prompt="How much of the deposit was withheld?"
              placeholder="Amount of deposit was withheld"
              value={reviewData.depositWithheld ? reviewData.depositWithheld.toString() : ''}
              setValue={(val) => {
                if (isLoggedIn) {
                  updateReview(
                    token,
                    { depositWithheld: val.length > 0 ? parseInt(val) : null },
                    reviewData.id
                  )
                }
                setReviewData({
                  ...reviewData,
                  depositWithheld: val.length > 0 ? parseInt(val) : null,
                  status: 'draft',
                })
              }}
              minWords={null}
              eventName="deposit_withheld"
              icon={
                <TbCurrencyDollar
                  color={`${!reviewData.depositWithheld ? '#5E6C84' : '#061938'}`}
                />
              }
            />
            <ReviewTextInput
              containerCss="max-w-full mb-2"
              customCss="pl-8"
              prompt="How much was the total deposit?"
              placeholder="Amount of the total deposit"
              value={reviewData.totalDeposit ? reviewData.totalDeposit.toString() : ''}
              setValue={(val) => {
                if (isLoggedIn) {
                  updateReview(
                    token,
                    { totalDeposit: val.length > 0 ? parseInt(val) : null },
                    reviewData.id
                  )
                }
                setReviewData({
                  ...reviewData,
                  totalDeposit: val.length > 0 ? parseInt(val) : null,
                  status: 'draft',
                })
              }}
              minWords={null}
              eventName="total_deposit"
              icon={
                <TbCurrencyDollar color={`${!reviewData.totalDeposit ? '#5E6C84' : '#061938'}`} />
              }
            />
            <SectionSubheader style="text-dark-700 font-semibold text-base">
              Why was the deposit withheld?
            </SectionSubheader>
            <CustomSelect
              customCss="mt-2 whitespace-nowrap"
              inputCustomHeight="40px"
              placeholder={'Pick a reason'}
              value={
                reviewData?.reasonDepositWithheld
                  ? {
                      value: reviewData.reasonDepositWithheld,
                      label: reviewData.reasonDepositWithheld,
                    }
                  : null
              }
              options={[
                { value: 'No justifiable reason', label: 'No justifiable reason' },
                { value: 'Some damages', label: 'Some damages' },
                { value: 'Cover unpaid rent', label: 'Cover unpaid rent' },
                { value: 'Cover a lease break', label: 'Cover a lease break' },
                { value: 'Other', label: 'Other' },
              ]}
              isSearchable={false}
              onFocus={() => {
                segment?.review_input_reason_deposit_withheld()
              }}
              onChange={(item) => {
                if (isLoggedIn) {
                  // @ts-expect-error option item unknown
                  updateReview(token, { reasonDepositWithheld: item.value }, reviewData.id)
                }
                // @ts-expect-error option item unknown
                setReviewData({ ...reviewData, reasonDepositWithheld: item.value, status: 'draft' })
              }}
            />
          </div>
        )}
      </div>
      <div className="mt-6 rounded-xl bg-light-10 p-4">
        <SectionSubheader style="text-dark-700 font-semibold text-base">
          Is this building pet-friendly?
        </SectionSubheader>
        <RadioButton
          style="mt-4"
          options={{ Yes: true, No: false }}
          eventName="is_pet_friendly"
          segmentBase="review"
          selectedValue={reviewData.isPetFriendly}
          onSelect={(val) => {
            let newVal = val
            if (val == reviewData.isPetFriendly) {
              newVal = null
            }
            if (isLoggedIn) {
              updateReview(token, { isPetFriendly: newVal, hasPetFee: null }, reviewData.id)
            }
            setReviewData({
              ...reviewData,
              isPetFriendly: newVal,
              hasPetFee: null,
              status: 'draft',
            })
          }}
        />
        {reviewData.isPetFriendly && (
          <div className="mt-6">
            <SectionSubheader style="text-dark-700 font-semibold text-base">
              Is there a pet fee?
            </SectionSubheader>
            <RadioButton
              style="mt-4"
              options={{ Yes: true, No: false }}
              eventName="has_pet_fee"
              segmentBase="review"
              selectedValue={reviewData.hasPetFee}
              onSelect={(val) => {
                let newVal = val
                if (val == reviewData.hasPetFee) {
                  newVal = null
                }
                if (isLoggedIn) {
                  updateReview(token, { hasPetFee: newVal }, reviewData.id)
                }
                setReviewData({ ...reviewData, hasPetFee: newVal, status: 'draft' })
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}
