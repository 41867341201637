import dynamic from 'next/dynamic'
import { useEffect, useState } from 'react'
import { segment } from 'src/lib/Segments'
import { v4 as uuidv4 } from 'uuid'

import OIIcon from '@components/icons'

import MobileNativeShare from './MobileNativeShare'
import ShareOptions from './ShareOptions'

// import { IconProps } from 'react-web-share/dist/interfaces'
// import { IconContext } from 'react-icons/lib'

const ReactTooltip = dynamic(() => import('react-tooltip'), { ssr: false })

declare type Props = {
  title: string
  url: string
  label?: string
  containerStyle?: string
  shareNatively?: boolean
  showIcon?: boolean
  customIconName?: any
  carouselTooltipCss?: string
  segmentBase?: string
  customEmailTitle?: string
  customEmailBody?: string
  getShareOpt?: (string) => void
  shareClicked?: () => void
}

export default function ShareItem({
  title,
  url,
  label = 'Share',
  containerStyle = null,
  shareNatively = true,
  showIcon = true,
  customIconName,
  carouselTooltipCss = '',
  segmentBase = 'other',
  customEmailTitle = null,
  customEmailBody = null,
  getShareOpt = () => {},
  shareClicked = () => {},
}: Props) {
  const toolTipId = uuidv4()
  const [hasNativeShare, setHasNativeShare] = useState(false)

  useEffect(() => {
    setHasNativeShare(navigator && navigator?.share && shareNatively)
  }, [])

  return hasNativeShare ? (
    <MobileNativeShare
      showIcon={showIcon}
      postTitle={title}
      url={url}
      label={label}
      containerStyle={containerStyle}
      segmentBase={segmentBase}
    />
  ) : (
    <>
      <div
        className={
          containerStyle ||
          'relative z-10 flex cursor-pointer items-center space-x-2 rounded-3xl border border-light-40 py-1 px-2 text-sm font-thin'
        }
        data-for={toolTipId}
        data-event="click focus"
        data-tip="custom show"
      >
        {showIcon && <OIIcon name={customIconName ?? 'share'} className="" size="md" />}
        <span>{label}</span>
      </div>
      <ReactTooltip
        id={toolTipId}
        type="dark"
        effect="float"
        globalEventOff="click"
        place="top"
        afterShow={() => {
          shareClicked()
          segment.global_share_openigloo?.(segmentBase)
        }}
        clickable={true}
        wrapper="div"
        className={`tooltip-inline m-0 inline-flex select-none flex-row flex-nowrap items-center 
          justify-center space-x-2 bg-black p-1 text-center text-white hover:cursor-pointer ${carouselTooltipCss}`}
      >
        <ShareOptions
          title={title}
          url={url}
          customEmailTitle={customEmailTitle}
          customEmailBody={customEmailBody}
          onShare={(via) => {
            getShareOpt(via)
            segment?.['global' + via]?.(segmentBase, url)
          }}
        />
      </ReactTooltip>
    </>
  )
}