import { ReviewApi } from 'src/common/types'

import { ShortRating } from '..'

type UnitDetailsFullscreenProps = {
  review: ReviewApi
}

function ReviewImageDetails({ review }: UnitDetailsFullscreenProps) {
  return (
    <div className="sm:flex">
      <div className="flex items-center py-5 px-2.5 sm:w-fit sm:py-0 sm:px-0">
        <div className="text-xl text-white">{review?.title}</div>
        <div className="flex px-3 pl-5 text-xl font-semibold text-light-0">
          <ShortRating value={review.rating} titleStyle="text-base" />
        </div>
      </div>
    </div>
  )
}

export default ReviewImageDetails
