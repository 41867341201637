import {
  GoogleAuthProvider,
  OAuthProvider,
  browserPopupRedirectResolver,
  getAdditionalUserInfo,
  signInWithPopup,
} from 'firebase/auth'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { IoChevronBack } from 'react-icons/io5'
import { getFirebaseAuth } from 'src/lib/firebase'

import Chatlio from '@components/Chatlio'
import { OIModal } from '@components/UI/organisms'
import { GrClose } from '@react-icons/all-files/gr/GrClose'
import { setCurrentUserProfile } from '@redux/actions'
import { useAppSelector, useThunkDispatch } from '@redux/hooks'

import { segment } from '../../lib/Segments'
import { getAuthPrompt } from './AuthPrompts'
import Login from './Login'
import LoginOrSignup from './LoginOrSignup'
import Signup from './Signup'

const auth = getFirebaseAuth()

export interface LoginModalProps {
  onClose: () => void
  AuthPrompt?: React.FC
}

const LoginModal = ({ onClose, AuthPrompt }: LoginModalProps) => {
  const { authRequired } = useAppSelector((state) => state.currentUser)
  const router = useRouter()
  const dispatch = useThunkDispatch()

  const [errorMsg, setErrorMsg] = useState(null)
  const [errorBannerVisible, setErrorBannerVisible] = useState(null)
  const [email, setEmail] = useState(authRequired?.emailPrefill)
  const [currentScreen, setCurrentScreen] = useState<'loginSignup' | 'login' | 'signup'>(
    'loginSignup'
  )

  useEffect(() => {
    segment.login_signup_attempt()
  }, [])

  useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        setErrorMsg(null)
      }, 3000)
    }
  }, [errorMsg])
  useEffect(() => {
    if (errorBannerVisible) {
      setTimeout(() => {
        setErrorBannerVisible(false)
      }, 10000)
    }
  }, [errorBannerVisible])

  const [token, setToken] = useState(null)

  const CustomHeader = () => {
    const getScreenName = () => {
      switch (currentScreen) {
        case 'loginSignup':
          return `Log in / Sign up`
        case 'login':
          return `Log in`
        case 'signup':
          return `Sign up`
      }
    }

    return (
      <div className="flex w-full items-center justify-between border-b bg-light-10 px-8 py-4 text-xl text-dark-900 md:justify-start md:py-6">
        {currentScreen !== 'loginSignup' ? (
          <IoChevronBack
            className="md:mr-4"
            onClick={() => {
              setCurrentScreen('loginSignup')
              segment.login_signup_backbutton()
            }}
          />
        ) : null}
        <span className="flex-1 text-center md:text-left">{getScreenName()}</span>
        <GrClose
          className="cursor-pointer"
          size={16}
          onClick={() => {
            segment.login_signup_close()
            onClose()
          }}
        />
      </div>
    )
  }

  const Prompt = AuthPrompt || getAuthPrompt(authRequired.prompt)

  const onLoginViaProvider = async (provider) => {
    let authProvider: OAuthProvider | GoogleAuthProvider
    if (provider === 'google') {
      authProvider = new GoogleAuthProvider()
    } else if (provider === 'apple') {
      authProvider = new OAuthProvider('apple.com')
      authProvider.addScope('email')
      authProvider.addScope('name')
    }
    try {
      const result = await signInWithPopup(auth, authProvider, browserPopupRedirectResolver)
      const token = await result.user.getIdToken()
      const userInfo = getAdditionalUserInfo(result)
      const userEmail = userInfo.profile.email as string
      if (userInfo.isNewUser) {
        setEmail(userEmail)
        setToken(token)
        if (provider === 'google') {
          segment.signup_google(userEmail, '')
        } else if (provider === 'apple') {
          segment.signup_apple(userEmail, '')
        }
      } else {
        if (provider === 'google') {
          segment.login_google(userEmail)
        } else if (provider === 'apple') {
          segment.login_apple(userEmail)
        }
      }
      await dispatch(setCurrentUserProfile(token))
      setCurrentScreen('signup')
      onClose()
    } catch (e) {
      if (e.code === 'auth/popup-blocked') {
        setErrorMsg(
          'Please enable pop-ups in your browser settings or try logging in again to access your account.'
        )
      } else if (
        e.code === 'auth/cancelled-popup-request' ||
        e.code === 'auth/popup-closed-by-user'
      ) {
        setErrorBannerVisible(true)
      } else {
        setErrorMsg('Something went wrong!')
      }
    }
  }

  const getCurrentScreen = () => {
    switch (currentScreen) {
      case 'loginSignup':
        return (
          <>
            <Prompt />
            <LoginOrSignup
              onLogin={(provider, email, isNewUser = false) => {
                if (provider === 'email' && email) {
                  setEmail(email)
                  if (isNewUser) {
                    setCurrentScreen('signup')
                  } else {
                    setCurrentScreen('login')
                  }
                } else if (['google', 'apple'].includes(provider)) {
                  onLoginViaProvider(provider)
                }
              }}
            />
          </>
        )
      case 'login':
        return <Login onError={(error) => setErrorMsg(error)} email={email} />
      case 'signup':
        return <Signup onError={(error) => setErrorMsg(error)} token={token} email={email} />
      default:
        break
    }
  }

  const visitChatSupport = () => {
    router.push(
      {
        pathname: '/profile',
        query: { q: 'chat' },
      },
      undefined
    )
  }

  return (
    <OIModal
      onClose={() => {
        segment.login_signup_close()
        onClose()
      }}
      customModalStyle="!max-w-md md:h-auto"
      customHeader={<CustomHeader />}
      useStandardHeader={false}
      closeOnOutsideClick={false}
      closeOnEscape={false}
    >
      <>
        <div className="flex h-full flex-col overflow-y-auto p-8 pt-4 transition-all sm:min-h-full sm:pb-4">
          {getCurrentScreen()}
          {errorBannerVisible ? (
            <div className="rounded-xl bg-red-50 p-4">
              <h3 className="mb-2 text-sm font-semibold text-red-500">
                Couldn't reach your account
              </h3>
              <span className="text-sm text-red-400">
                There was an unexpected glitch. Please try again, or reach out via{' '}
                <span className="cursor-pointer text-primary underline" onClick={visitChatSupport}>
                  support chat
                </span>{' '}
                to troubleshoot.
              </span>
            </div>
          ) : null}
          <div className="my-4 flex h-6 items-center justify-center p-2 py-1 text-dark-900">
            {errorMsg ? <span className="text-red-400">{errorMsg}</span> : null}
          </div>
          <div className="flex-1 my-6 flex flex-col justify-end">
            <p className="text-xs leading-5 text-mid-400">
              By selecting Login, Continue with Google or Continue with Apple, you agree to our{' '}
              <Link passHref href={'/terms-of-use'}>
                <a
                  onClick={() => segment.login_signup_terms_conditions()}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-bluegray-300 underline"
                >
                  Terms and Conditions
                </a>
              </Link>{' '}
              and{' '}
              <Link passHref href={'/privacy-policy'}>
                <a
                  onClick={() => segment.login_signup_privacy_statement()}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-bluegray-300 underline"
                >
                  Privacy Statement
                </a>
              </Link>
            </p>
            <p className="text-xs leading-5 text-mid-400">
              If you have any questions, don't hesitate to reach out
            </p>
            <p className="text-xs leading-5 text-mid-400">
              via{' '}
              <u
                className="inline cursor-pointer text-bluegray-300 underline"
                onClick={() => {
                  // @ts-expect-error ignore chatlio
                  if (window._chatlio) {
                    // @ts-expect-error ignore chatlio
                    _chatlio.open()
                  }
                }}
              >
                support chat
              </u>{' '}
            </p>
          </div>
        </div>
        <Chatlio />
      </>
    </OIModal>
  )
}

export default LoginModal
