import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { BeatLoader } from 'react-spinners'
import { ReviewApi } from 'src/common/types'
import { getUserReviews, reviewDeleteApi } from 'src/data/Review'

import { OIButton, OIButtonType } from '@components/UI/atoms'
import { OIModal } from '@components/UI/organisms'
import { Section, SectionHeader } from '@components/index'
import NewReviewCard from '@components/review/NewReviewCard'
import { setAuthRequired } from '@redux/actions'
import { useAppSelector } from '@redux/hooks'

import { segment } from '../../lib/Segments'

type Props = {
  isProfileView?: boolean
  showEditOptions?: boolean
}

const ReviewsView = ({ isProfileView = false, showEditOptions = true }: Props) => {
  const { token, isLoggedIn } = useAppSelector((state) => state.currentUser)

  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false)
  const [reviewToDelete, setReviewToDelete] = useState<ReviewApi>(null)
  const [reviewData, setReviewData] = useState<ReviewApi[]>([])
  const [reviewLoader, setReviewLoader] = useState(true)

  const dispatch = useDispatch()
  const router = useRouter()

  useEffect(() => {
    if (token === null && !isProfileView) {
      dispatch(setAuthRequired(true))
    }
  }, [])

  useEffect(() => {
    if (isLoggedIn) {
      getUserReview()
    } else {
      setTimeout(() => {
        setReviewLoader(false)
      }, 3000)
    }
  }, [isLoggedIn])

  async function getUserReview() {
    setReviewLoader(true)
    const review = await getUserReviews(token)
    setReviewData(review)
    setReviewLoader(false)
  }

  function editReview(review: ReviewApi) {
    segment.profile_review_edit(review.id)
    router.push(`/review/${review.buildingSlug}`)
  }

  async function onDeleteReview(review: ReviewApi) {
    await reviewDeleteApi(token, review.id)
    await getUserReview()
    setShowDeleteConfirmModal(false)
  }

  return (
    <div>
      {showDeleteConfirmModal && (
        <OIModal
          onClose={() => setShowDeleteConfirmModal(false)}
          useStandardHeader={false}
          customModalStyle="md:w-[40%] w-6/6 text-center"
        >
          <div className="p-8">
            <div className="text-xl font-semibold text-dark-900"></div>
            <h1 className="mt-4 text-xl font-semibold">Are you sure?</h1>
            <div className="pt-2 text-dark-700">
              By deleting your review, you might lose full access to openigloo.
            </div>
            <div className="mt-6 flex w-full flex-col items-center">
              <OIButton
                label="Delete review"
                style="!text-sm w-1/2 sm:w-2/5"
                onClick={() => {
                  onDeleteReview(reviewToDelete)
                  segment.profile_review_delete_confirm(reviewToDelete.id)
                }}
              />
              <OIButton
                style="text-dark-600 !text-sm mt-4 w-1/2 sm:w-2/5"
                onClick={() => {
                  segment.profile_review_delete_cancel(reviewToDelete.id)
                  setShowDeleteConfirmModal(false)
                }}
                type={OIButtonType.TERTIARY}
                label={`Cancel`}
              />
            </div>
          </div>
        </OIModal>
      )}
      {!isProfileView && <SectionHeader>Your Reviews</SectionHeader>}
      <Section>
        {reviewLoader && !isProfileView ? (
          <div className="mx-auto mt-8 mb-16 text-center">
            <BeatLoader size={10} color="#C1C7D0" />
          </div>
        ) : reviewData?.length > 0 ? (
          (isProfileView ? reviewData.slice(0, 1) : reviewData).map((review, index) => {
            return (
              <NewReviewCard
                showEditOptions={showEditOptions}
                review={review}
                key={`review-${index}`}
                onEditReview={editReview}
                onDeleteReview={() => {
                  segment.profile_review_delete(review.id)
                  setShowDeleteConfirmModal(true)
                  setReviewToDelete(review)
                }}
                showStatus={true}
                showReviewActions={false}
                segmentBase="profile"
                isProfileView={isProfileView}
              />
            )
          })
        ) : !isProfileView ? (
          <div className="flex h-56 flex-col items-center space-y-4">
            <span>No reviews yet!</span>
            <a
              onClick={segment.profile_write_review}
              className="w-48 rounded-full bg-bluegray-300 p-2 text-center text-white"
              href="/review"
            >
              Write a Review
            </a>
          </div>
        ) : (
          <div className="text-sm text-dark-600">Your most recent review will appear here.</div>
        )}
      </Section>
    </div>
  )
}

export default ReviewsView
