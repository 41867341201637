import Head from 'next/head'

import React from 'react'

import { Post } from '../../common/types'

interface Props {
  post: Post
}

const BlogPostMeta = ({ post }: Props): JSX.Element => {
  const postStructuredData = {
    '@type': 'NewsArticle',
    '@context': 'http://schema.org/',
    headline: post.title,
    articleBody: post.contentHtml,
    abstract: post.excerpt,
    datePublished: new Date(post.date),
    author: {
      '@type': 'Organization',
      name: post.by,
    },
    publisher: {
      '@type': 'Organization',
      name: 'openigloo',
      logo: 'https://www.openigloo.com/logo.png',
    },
    inLanguage: 'English',
    isAccessibleForFree: true,
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `https://openigloo.com/blogs/${post.slug}`,
    },
    image: `https://openigloo.com${post.cover}`,
    url: `https://openigloo.com/blogs/${post.slug}`,
  }

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(postStructuredData) }}
      ></script>
    </Head>
  )
}

export default BlogPostMeta
