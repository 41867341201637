import Head from 'next/head'
import React from 'react'
import { jsonLdScriptProps } from 'react-schemaorg'
import { AggregateRating, LocalBusiness, Person, WithContext } from 'schema-dts'
import { cityChoices } from 'src/common/Constants'
import { BuildingApi, ContactApi } from 'src/common/types'

import { Review } from '@openigloo/common'
import { titleCase } from '@utility/Utilities'

interface Props {
  contact: ContactApi
  buildings?: BuildingApi[]
  reviews?: Review[]
}

export function getIndividualContactMeta(
  contact: ContactApi,
  associatedBuildings: BuildingApi[]
): Person {
  const ownerBuilding = associatedBuildings[0]
  let customDescription
  if (ownerBuilding?.primaryAddress?.fullAddress) {
    customDescription = `${contact.name} owns ${ownerBuilding.primaryAddress?.fullAddress} and ${
      contact.numBuildings - 1
    } others.`
  } else {
    customDescription = `${contact.name}`
  }
  return {
    '@context': 'https://schema.org',
    '@type': 'Person',
    name: titleCase(contact.name),
    address: associatedBuildings.map((bld) => ({
      '@type': 'PostalAddress',
      streetAddress: titleCase(bld.primaryAddress?.fullAddress ?? ''),
      addressLocality: titleCase(bld.neighborhood.borough ?? ''),
      addressRegion: 'NY',
      postalCode: bld.zipCode,
    })),
    image: associatedBuildings.map((bld) => bld?.images?.[0]?.url),
    url: `https://openigloo.com/contact/${
      cityChoices.find((x) => x.label == contact?.city).slug ?? 'nyc'
    }/${contact.id}/${contact.slug}`,
    description: customDescription,
  } as Person
}

export function getInstitutionalContactMeta(
  contact: ContactApi,
  associatedBuildings: BuildingApi[],
  associatedReviews?: Review[]
): LocalBusiness {
  const ownerBuilding = associatedBuildings[0]
  let customDescription
  if (ownerBuilding?.primaryAddress?.fullAddress) {
    customDescription = `${contact.name} owns ${ownerBuilding.primaryAddress?.fullAddress} and ${
      contact.numBuildings - 1
    } others.`
  } else {
    customDescription = `${contact.name}`
  }
  return {
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',
    name: titleCase(contact.name || 'Unknown'),
    address: associatedBuildings.map((bld) => ({
      '@type': 'PostalAddress',
      streetAddress: titleCase(bld.primaryAddress?.fullAddress ?? ''),
      addressLocality: titleCase(bld.neighborhood?.borough ?? ''),
      addressRegion: 'NY',
      postalCode: bld.zipCode,
    })),
    aggregateRating:
      contact.reviewCount > 0
        ? {
            worstRating: 1,
            bestRating: 5,
            ratingCount: contact.reviewCount,
            ratingValue: contact.averageRating,
            name: titleCase(contact.name || 'Unknown'),
          }
        : ({} as AggregateRating),
    image: associatedBuildings.map((bld) => bld?.images?.[0]?.url),
    url: `https://openigloo.com/contact/${
      cityChoices.find((x) => x.label == contact?.city)?.slug ?? 'nyc'
    }/${contact.id}/${contact.slug}`,
    description: customDescription,
    review: associatedReviews?.map((review) => ({
      '@context': 'https://schema.org',
      '@type': 'UserReview',
      name: review.title,
      reviewAspect: 'Building and building management',
      reviewBody: `Pros: ${review.pros} Cons: ${review.cons} Advice to owner: ${review.advice}`,
      reviewRating: {
        '@type': 'Rating',
        author: {
          '@type': 'Person',
          name: 'openigloo tenant',
        },
        bestRating: 5,
        worstRating: 1,
        ratingValue: review.rating,
      },
      author: {
        '@type': 'Person',
        name: 'openigloo tenant',
      },
    })),
  } as LocalBusiness
}

const ContactMeta = ({ contact, buildings, reviews = [] }: Props): JSX.Element => {
  const contactStructuredData = contact.isIndividual
    ? getIndividualContactMeta(contact, buildings)
    : getInstitutionalContactMeta(contact, buildings, reviews)
  return (
    <Head>
      {contact.isIndividual && (
        <script {...jsonLdScriptProps<Person>(contactStructuredData as WithContext<Person>)} />
      )}
      {!contact.isIndividual && (
        <script
          {...jsonLdScriptProps<LocalBusiness>(contactStructuredData as WithContext<LocalBusiness>)}
        />
      )}
    </Head>
  )
}

export default ContactMeta
