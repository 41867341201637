import Image from 'next/image'
import { useCallback, useEffect, useState } from 'react'
import { UnitApi } from 'src/common/types'
import { segment } from 'src/lib/Segments'

import { OIPill, OIPillSize, OIPillStatus, OIPillType } from '@components/UI/atoms'
import SaveItem from '@components/generic/SaveItem'
import { saveUnit } from '@redux/actions/user'
import { useAppSelector, useThunkDispatch } from '@redux/hooks'
import { currency, splitAddress } from '@utility/Utilities'

import UnitFeatures from './unitFeatures'
import UnitInterest from './unitInterest'

type Props = {
  data: UnitApi
  segmentBase?: string
}

function UnitCards({ data, segmentBase = 'building' }: Props) {
  const dispatch = useThunkDispatch()
  const { savedUnits, contactedUnits } = useAppSelector((store) => store.currentUser)
  const { addressLine1 } = splitAddress(data.fullAddress)
  const [hasUserContacted, setHasUserContacted] = useState(false)
  const isSavedUnit = useCallback(
    () => savedUnits && savedUnits.map((u) => u.id).includes(data.id),
    [savedUnits, data]
  )
  const [randomNumber, setRandomNumber] = useState(null)

  useEffect(() => {
    if (!randomNumber) {
      const randomNum = Math.floor(Math.random() * 5) + 1
      setRandomNumber(randomNum)
    }
  }, [randomNumber])

  const memoizedRandomImage = useCallback(() => randomNumber, [randomNumber])

  useEffect(() => {
    setHasUserContacted(contactedUnits.find((x) => x.slug == data.slug) ? true : false)
  }, [contactedUnits])

  return (
    <>
      <a
        rel="noreferrer"
        onClick={(e) => {
          const target = e.target as HTMLElement

          // If clicking on UnitInterest, prevent link navigation
          if (target.closest('.prevent-link')) {
            e.preventDefault()
          } else {
            // Manually open the link in a new tab
            window.open(`/unit/nyc/${data.slug}/${data.addressSlug}`, '_blank')
          }
        }}
      >
        <div
          className="relative flex cursor-pointer items-center gap-2 rounded-xl border border-light-40 p-4"
          onClick={() => {
            segmentBase === 'review'
              ? segment?.[segmentBase + '_success_listingcard']?.()
              : segment?.[segmentBase + '_listingcard']?.()
          }}
        >
          <Image
            src={(() => {
              const validImage = data.images?.find((img) => {
                const url = img?.imageS || img?.url
                if (url) {
                  return url
                }
                return false
              })

              return validImage ? validImage.url : `/unit_placeholder${memoizedRandomImage()}.png`
            })()}
            placeholder="blur"
            width={112}
            height={180}
            blurDataURL="/building-card-placeholder.png"
            alt="unit"
            className="rounded-xl"
            objectFit="cover"
            objectPosition="center"
            unoptimized
          />
          <div>
            <div className="text-sm text-dark-700">{data.neighborhood.name}</div>
            <div className="pt-2 font-semibold text-dark-900">{addressLine1}</div>
            <div className="py-2 font-semibold text-dark-900">
              {currency(data.activeListing.rent)}
            </div>
            <UnitFeatures unit={data} showBuildingRating={false} />
            <div
              className="prevent-link"
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              <UnitInterest
                listing={data}
                hasUserContacted={hasUserContacted}
                segmentBase={segmentBase}
                addOnClass={`mt-2 !bg-white border-none underline py-0 px-0 ${
                  hasUserContacted ? '!text-mid-300' : '!text-bluegray-300'
                }`}
                size="none"
                showIcon={true}
                onClick={() => {
                  segment?.[segmentBase + '_listing_contactagent']?.()
                  segment?.[segmentBase + '_success_contactagent']?.()
                }}
              />
            </div>
          </div>
          <div className="absolute left-6 top-6 z-10">
            <SaveItem
              isItemSaved={isSavedUnit()}
              onSave={(s) => {
                dispatch(saveUnit(data, s))
                segmentBase == 'landing'
                  ? segment?.home_verified_listings_save()
                  : isSavedUnit()
                  ? segment.global_unitcard_unsave?.(segmentBase, data.slug, data.addressSlug)
                  : segment.global_unitcard_save?.(segmentBase, data.slug, data.addressSlug)
              }}
              hasBackground={true}
            />
          </div>
          <div className="absolute left-6 bottom-6 z-10">
            {!data.activeListing ? (
              <OIPill
                size={OIPillSize.LARGE}
                type={OIPillType.PRIMARY}
                label="Closed"
                status={OIPillStatus.ERROR}
              />
            ) : (
              <>
                {data.isBuildingVerified && (
                  <OIPill
                    type={OIPillType.PRIMARY_DARK}
                    style={`!py-1.5 !border !border-blue-25`}
                    label="Verified"
                  />
                )}
                {data.activeListing?.isFutureListing && (
                  <div>
                    <OIPill
                      type={OIPillType.SECONDARY}
                      style={`!py-1.5 !border !border-bluegray-75 text-blue-200 mt-1`}
                      label="Future"
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </a>
    </>
  )
}

export default UnitCards
