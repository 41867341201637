import React from 'react'

type SectionHeaderType = {
  containerStyle?: string
  children?: React.ReactChild
  required?: boolean
  id?: string
}
export default function SectionHeader({
  children = '',
  containerStyle = '',
  required = false,
}: SectionHeaderType): JSX.Element {
  return (
    <div className={`mt-2 flex flex-row text-lg font-bold tracking-wide ${containerStyle}`}>
      {children}
      {required && <div style={{ color: 'red' }}> *</div>}
    </div>
  )
}
