import { Review, ReviewSteps, Tenancy } from '@openigloo/common'

import { userReviewSlice } from '../reducers/userReview'
import { AppState } from '../store'

// const COVID_DATE = new Date(2020, 3, 1)

// function isNotCovidTenancy(moveOut?: Date) {
//   return moveOut && moveOut < COVID_DATE
// }

export const setIsFetching = (value: boolean) => (dispatch) => {
  dispatch(userReviewSlice.actions.setIsFetching(value))
}

export const updateRatings = (ratingTypeId: string, ratingValue: number) => (dispatch) => {
  dispatch(userReviewSlice.actions.setRating({ ratingTypeId, ratingValue }))
}

export const updateReview = (newReview: Review) => (dispatch) => {
  dispatch(userReviewSlice.actions.setReviewBody(newReview))
}

export const updateTenancy = (newTenancy: Tenancy) => (dispatch) => {
  dispatch(userReviewSlice.actions.setTenancy(newTenancy))
}

export const toNextStage =
  (isEditMode = false) =>
  (dispatch, getState: () => AppState) => {
    const {
      currentUserReview: { stage, lastStage },
      currentUser: { token },
    } = getState()
    if (stage === ReviewSteps.TENANCY) {
      dispatch(userReviewSlice.actions.setStage(ReviewSteps.RENTPRICES))
    } else if (stage === lastStage || isEditMode) {
      if (token) {
        dispatch(userReviewSlice.actions.setStage(ReviewSteps.SUCCESS))
      }
    } else {
      dispatch(userReviewSlice.actions.setStage(stage + 1))
    }
  }

export const toPrevStage = () => (dispatch, getState: () => AppState) => {
  const {
    currentUserReview: { stage },
  } = getState()
  if (stage > ReviewSteps.RATING) {
    // if (
    //   stage === ReviewSteps.RENTPRICES
    // ) {
    //   dispatch(userReviewSlice.actions.setStage(stage - 2))
    // } else {
    dispatch(userReviewSlice.actions.setStage(stage - 1))
    // }
  }
}

export const setPhotosToUpload = (files: any) => (dispatch) => {
  dispatch(userReviewSlice.actions.setPhotosToUpload(files))
}

export const resetUserReview = () => (dispatch) => {
  dispatch(userReviewSlice.actions.resetAll())
}
