//  Ideas comes from https://github.com/ekeric13/react-star-ratings
import { NextPage } from 'next/types'

interface Props {
  fillId?: string
  starRating?: number
  starIndex?: number
  isStatic?: boolean
  starFillStatus?: string
  fillColor?: string
  emptyColor?: string
  hasNoRightMargin?: boolean
  starSpace?: number
  starSize?: number

  onRatingChange?: (rating: number) => void
  setHoverIndex?: (index: number) => void
}

interface pathStyle {
  fill: string
}

const getPathStyle = (fillId, starFillStatus, fillColor, emptyColor): pathStyle => {
  let fill = ''
  if (starFillStatus === 'empty') {
    fill = emptyColor
  } else if (starFillStatus === 'partial') {
    fill = `url('#${fillId}')`
  } else if (starFillStatus === 'full') {
    fill = fillColor
  }
  const pathStyle = {
    fill: fill,
    // transition: 'fill .2s ease-in-out',
  }

  return pathStyle
}

const Star: NextPage<Props> = ({
  fillId,
  starFillStatus,
  fillColor,
  emptyColor,
  starSpace,
  isStatic,
  starIndex,
  hasNoRightMargin,
  starRating,
  onRatingChange,
  setHoverIndex,
}) => {
  return (
    <button
      className={`${
        isStatic ? 'cursor-default' : 'cursor-pointer'
      } relative  inline-block focus:outline-none`}
      style={{ marginRight: `${hasNoRightMargin ? '0' : starSpace}px` }}
      onClick={() => {
        if (onRatingChange) onRatingChange(starRating)
      }}
      onMouseEnter={() => {
        if (setHoverIndex) setHoverIndex(starIndex)
      }}
      onMouseLeave={() => {
        if (setHoverIndex) setHoverIndex(-1)
      }}
    >
      <svg
        className="h-4 w-4 sm:h-5 sm:w-5"
        // style={{ width: `${starSize}px`, height: `${starSize}px` }}
        viewBox="0 0 16 16"
      >
        {/* We could change the star style later by changing the path here. */}
        {/* <path className='fill-current' d='M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.523-3.356c.329-.314.158-.888-.283-.95l-4.898-.696L8.465.792a.513.513 0 00-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767l-3.686 1.894.694-3.957a.565.565 0 00-.163-.505L1.71 6.745l4.052-.576a.525.525 0 00.393-.288l1.847-3.658 1.846 3.658a.525.525 0 00.393.288l4.052.575-2.906 2.77a.564.564 0 00-.163.506l.694 3.957-3.686-1.894a.503.503 0 00-.461 0z' style={getPathStyle(fillId, starFillStatus, fillColor, emptyColor)}/> */}
        <path
          className="fill-current"
          d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"
          style={getPathStyle(fillId, starFillStatus, fillColor, emptyColor)}
        />
      </svg>
    </button>
  )
}

export default Star
