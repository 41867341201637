import Image from 'next/image'
import { useEffect, useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

type SubSliderImageProps = {
  images: any
  activateFloorPlan: boolean
  showFullPage: boolean
  hasFloorPlan?: boolean
  customWidthSubSlide: string
  customWidthFloorPlan: string
  callbackImage: (slide: number) => void
  setNav2: React.Dispatch<React.SetStateAction<any>>
  setActivateFloorPlan: React.Dispatch<React.SetStateAction<boolean>>
  nav1: any
}

function SubSliderImage({
  images,
  activateFloorPlan,
  nav1,
  setActivateFloorPlan,
  showFullPage,
  setNav2,
  hasFloorPlan = false,
  customWidthSubSlide,
  customWidthFloorPlan,
}: SubSliderImageProps) {
  const [secondSliderImages, setSecondSliderImages] = useState([])

  useEffect(() => {
    setSecondSliderImages(hasFloorPlan ? images.slice(0, images.length - 1) : images)
  }, [images])

  return (
    <div
      className={`hidden flex-wrap justify-between sm:flex ${
        activateFloorPlan ? 'activePlan' : ''
      }`}
    >
      <div className="flex justify-between sm:w-full">
        <div
          className={`slider-1 ${
            showFullPage && hasFloorPlan ? 'w-85-9' : hasFloorPlan ? customWidthSubSlide : 'w-full'
          }`}
        >
          <Slider
            asNavFor={nav1}
            ref={(slider2: any) => setNav2(slider2)}
            swipeToSlide={false}
            focusOnSelect={true}
            className="slider-2"
            infinite={false}
            arrows={false}
            slidesToShow={
              showFullPage && hasFloorPlan ? 7 : showFullPage ? 8 : hasFloorPlan ? 4 : 5
            }
          >
            {secondSliderImages.map((data, index) => {
              const imageSrc = data?.imageS || data?.url || data
              return (
                <div className="relative h-20 cursor-pointer focus:outline-none" key={index}>
                  <Image
                    src={imageSrc}
                    alt={data?.imageDescription?.toString() ?? data?.description?.toString()}
                    layout="fill"
                    className="h-full w-full object-cover"
                    placeholder="blur"
                    blurDataURL="/building-card-placeholder.png"
                    unoptimized
                  />
                </div>
              )
            })}
          </Slider>
        </div>
        {hasFloorPlan && (
          <div className={showFullPage ? 'w-13-9' : customWidthFloorPlan}>
            <div className="flex justify-end">
              {images.length > 3 && (
                <div className="my-6 mr-1 ml-1.5 text-base text-bluegray-300">...</div>
              )}
              <div
                className={`relative h-20 w-full cursor-pointer border focus:outline-none 
                    ${
                      showFullPage && activateFloorPlan
                        ? 'rounded-2xl border-4 border-blue-25'
                        : activateFloorPlan
                        ? 'rounded-2xl border-4 border-bluegray-300'
                        : 'border-light-40'
                    }`}
                onClick={() => {
                  setActivateFloorPlan(true)
                  nav1.slickGoTo(images.length - 1)
                }}
              >
                <div
                  className={`absolute bottom-0 z-10 w-full bg-blue-25 py-0.5 pl-4 text-sm text-bluegray-300
                    ${activateFloorPlan && 'rounded-b-xl'}`}
                >
                  Floor Plan
                </div>
                <Image
                  src={images?.find((x) => x.isFloorPlan == true).url}
                  alt={'floor plan'}
                  layout="fill"
                  className={`h-full w-full rounded-br-3xl object-cover  
                    ${activateFloorPlan ? 'rounded-xl' : ''}`}
                  placeholder="blur"
                  blurDataURL="/building-card-placeholder.png"
                  unoptimized
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SubSliderImage
