import moment from 'moment'
import { useContext, useState } from 'react'
import { segment } from 'src/lib/Segments'
import { APContextReview } from 'src/pages/review/[buildingSlug]'

import CustomDatePicker from '@components/generic/CustomDatePicker'
import { updateReviewerData } from '@redux/actions'
import { useAppSelector, useThunkDispatch } from '@redux/hooks'
import { formatDate } from '@utility/Utilities'

import { SectionSubheader } from '..'
import CheckBox from './CheckBox'
import RadioButton from './RadioButton'
import ReviewTextInput from './ReviewTextInput'

const ethnicities = [
  'Alaska Native / American Indian',
  'Asian',
  'Black or African-American',
  'Hispanic or Latinx',
  'Native Hawaiian or Other Pacific Islander',
  'White',
]

const genders = ['Female', 'Male', 'Non-Binary']

function CreateReviewerDemog() {
  const { token, isLoggedIn } = useAppSelector((state) => state.currentUser)
  const { rvData, setRvData } = useContext(APContextReview)
  const dispatch = useThunkDispatch()

  const getOtherEthnicity = () => {
    if (rvData?.ethnicity) {
      return rvData.ethnicity.find((e) => !ethnicities.includes(e))
    }
    return ''
  }

  const getOtherGender = () => {
    if (rvData?.gender) {
      return rvData.gender.find((e) => !genders.includes(e))
    }
    return ''
  }

  const [otherEthnicity, setOtherEthnicity] = useState<string | null>(getOtherEthnicity())
  const [otherGender, setOtherGender] = useState<string | null>(getOtherGender())

  return (
    <div className="rounded-xl bg-white p-3 sm:p-6 sm:shadow">
      <div className="text-center text-xl font-semibold text-lilac-500 sm:text-left">
        Demographic Info
      </div>
      <div className="relative items-center pt-2 text-center sm:flex sm:text-left">
        <div className="pr-2 text-dark-700">
          At openigloo, we want to learn as much as we can about how tenant experiences vary across
          genders, ages, races, and immigration statuses. If you're comfortable, please consider
          sharing this info about yourself. It will be NOT be shared or attached to your review. But
          it could help us identify discrimination within landlord portfolios.
        </div>
      </div>
      <div className="pt-6">
        <SectionSubheader style="text-dark-900">
          Please indicate the racial and ethnic groups with which you identify (select all that
          apply):
        </SectionSubheader>
        <CheckBox
          options={ethnicities}
          eventName="racial_ethnic_group"
          segmentBase="review"
          selectedValues={rvData?.ethnicity || []}
          onSelect={(val) => {
            if (isLoggedIn) {
              dispatch(updateReviewerData(token, { ethnicity: val.sort() }))
            }
            setRvData({ ...rvData, ethnicity: val.sort() })
          }}
        />
        <ReviewTextInput
          prompt={null}
          containerCss="py-0 max-w-full"
          placeholder="Other?"
          eventName="other_racial_ethnic_group"
          value={otherEthnicity}
          setValue={setOtherEthnicity}
          onBlur={() => {
            if (otherEthnicity) {
              if (isLoggedIn) {
                dispatch(
                  updateReviewerData(token, {
                    ethnicity: [
                      ...(rvData?.ethnicity || []).filter((e) => ethnicities.includes(e)),
                      otherEthnicity,
                    ],
                  })
                )
              }
              setRvData({
                ...rvData,
                ethnicity: [
                  ...(rvData?.ethnicity || []).filter((e) => ethnicities.includes(e)),
                  otherEthnicity,
                ],
              })
            }
          }}
          minWords={null}
        />
      </div>
      <div className="pt-6">
        <SectionSubheader style="text-dark-900">What is your gender?</SectionSubheader>
        <CheckBox
          options={genders}
          selectedValues={rvData?.gender || []}
          onSelect={(val) => {
            if (isLoggedIn) {
              dispatch(updateReviewerData(token, { gender: val.sort() }))
            }
            setRvData({ ...rvData, gender: val.sort() })
          }}
          eventName="gender"
          segmentBase="review"
        />
        <ReviewTextInput
          prompt={null}
          containerCss="py-0 max-w-full"
          placeholder="Other?"
          value={otherGender}
          setValue={setOtherGender}
          eventName="other_gender"
          onBlur={() => {
            if (otherGender) {
              if (isLoggedIn) {
                dispatch(
                  updateReviewerData(token, {
                    gender: [
                      ...(rvData?.gender || []).filter((g) => genders.includes(g)),
                      otherGender,
                    ],
                  })
                )
              }
              setRvData({
                ...rvData,
                gender: [...(rvData?.gender || []).filter((g) => genders.includes(g)), otherGender],
              })
            }
          }}
          minWords={null}
        />
      </div>
      <div className="pt-6">
        <SectionSubheader style="text-dark-900">Your date of birth</SectionSubheader>
        <div className="mt-3 w-full">
          <CustomDatePicker
            placeholderText="YYYY-MM-DD"
            selectedDate={rvData?.dob}
            maxDate={new Date()}
            inputStyle={`!rounded-full !h-10 px-4 border border-light-40`}
            onChange={(e) => {
              if (isLoggedIn) {
                dispatch(updateReviewerData(token, { dob: formatDate(e, 'YYYY-MM-DD') }))
              }
              setRvData({ ...rvData, dob: formatDate(e, 'YYYY-MM-DD') })
              segment.review_birth('date', moment(e).format('DD'))
            }}
          />
        </div>
      </div>
      <div className="mt-6 rounded-xl bg-light-10 p-4">
        <SectionSubheader style="text-dark-700 font-semibold text-base">
          Are you a US Citizen?
        </SectionSubheader>
        <RadioButton
          style="pt-4"
          options={{ Yes: true, No: false }}
          eventName="us_citizen"
          segmentBase="review"
          selectedValue={rvData.isCitizen}
          onSelect={(val) => {
            let newVal = val
            if (val == rvData.isCitizen) {
              newVal = null
            }
            if (isLoggedIn) {
              dispatch(updateReviewerData(token, { isCitizen: newVal }))
            }
            setRvData({ ...rvData, isCitizen: newVal })
          }}
        />
      </div>
      <div className="mt-6 rounded-xl bg-light-10 p-4">
        <SectionSubheader style="text-dark-700 font-semibold text-base">
          Do you have a disability?
        </SectionSubheader>
        <RadioButton
          style="pt-4"
          options={{ Yes: true, No: false }}
          selectedValue={rvData.disability}
          onSelect={(val) => {
            let newVal = val
            if (val == rvData.disability) {
              newVal = null
            }
            if (isLoggedIn) {
              dispatch(updateReviewerData(token, { disability: newVal }))
            }
            setRvData({ ...rvData, disability: newVal })
          }}
          eventName="disability"
          segmentBase="review"
        />
      </div>
    </div>
  )
}

export default CreateReviewerDemog
