import { useState } from 'react'
import { FiChevronRight } from 'react-icons/fi'
import { IoMdCheckmark } from 'react-icons/io'
import { UnitApi } from 'src/common/types'
import { segment } from 'src/lib/Segments'

import ListingInterestModal from '@components/newListing/listingInterestModal'

import { ActionButton } from '..'

type UnitContactProps = {
  listing: UnitApi
  hasUserContacted?: boolean
  segmentBase: string
  addOnClass?: string
  size?: string
  showIcon?: boolean
  onClick?: () => void
}

function UnitInterest({
  listing,
  hasUserContacted,
  segmentBase,
  addOnClass = '',
  size = 'small',
  showIcon = false,
  onClick = () => {},
}: UnitContactProps) {
  const [showListingInterestModal, setShowListingInterestModal] = useState(false)

  return (
    <>
      <ActionButton
        label={
          hasUserContacted ? (
            <span className="inline-flex items-center gap-1">
              Contacted {showIcon && <IoMdCheckmark size={16} color="#5E6C84" />}
            </span>
          ) : (
            <span className="inline-flex items-center gap-1">
              Contact agent {showIcon && <FiChevronRight size={16} color="#6764A1" />}
            </span>
          )
        }
        onClick={(e) => {
          e.stopPropagation()
          onClick()
          segment?.[segmentBase + '_schedule_viewing']?.(listing.id, listing.addressSlug)
          setShowListingInterestModal(true)
        }}
        size={size}
        customStyle={`border border-bluegray-300 py-2 px-4 disabled:cursor-default text-sm ${addOnClass}`}
        disabled={hasUserContacted}
      />
      {showListingInterestModal && (
        <ListingInterestModal
          listing={listing}
          hasUserContacted={hasUserContacted}
          onClose={() => setShowListingInterestModal(false)}
          segmentBase={segmentBase}
        />
      )}
    </>
  )
}

export default UnitInterest
