import Link from 'next/link'
import { useEffect, useState } from 'react'
import { HiTrendingDown, HiTrendingUp } from 'react-icons/hi'
import BeatLoader from 'react-spinners/BeatLoader'
import { UnitApi } from 'src/common/types'
import { segment } from 'src/lib/Segments'

import { OIButtonSize, OIPrimaryButton } from '@components/UI/atoms'
import OISortButton from '@components/UI/molecules/OISortButton'
import OIIcon from '@components/icons'
import ListingUnitCards from '@components/newUnit/listingUnitCards'
import { useAppSelector, useThunkDispatch } from '@redux/hooks'
import { setCurrentUserSavedUnits } from '@redux/thunks/user'

const SavedListings = () => {
  const { token, savedUnits, loadingData } = useAppSelector((state) => state.currentUser)
  const dispatch = useThunkDispatch()

  const sortOptions = [
    {
      id: '0',
      label: 'High to low rent',
      icon: <HiTrendingDown size={18} />,
    },
    {
      id: '1',
      label: 'Low to high rent',
      icon: <HiTrendingUp size={18} />,
    },
  ]

  const [savedUnitsData, setSavedUnitsData] = useState<UnitApi[]>([])
  const [showActiveListings] = useState(true)
  const [isFetching, setIsFetching] = useState(true)
  const [filteredListings, setFilteredListings] = useState<UnitApi[]>([])
  const [selectedSortOption, setSelectedSortOption] = useState(null)

  useEffect(() => {
    if (token) {
      dispatch(setCurrentUserSavedUnits({ token }))
    }
  }, [token])

  useEffect(() => {
    const data = savedUnitsData.filter((listing) => !listing.activeListing == !showActiveListings)
    if (selectedSortOption) {
      if (selectedSortOption?.id === '0') {
        data.sort((l1, l2) => l2.activeListing.rent - l1.activeListing.rent)
      } else if (selectedSortOption?.id === '1') {
        data.sort((l1, l2) => l1.activeListing.rent - l2.activeListing.rent)
      }
    }
    setFilteredListings(data)
  }, [savedUnitsData, showActiveListings, selectedSortOption])

  useEffect(() => {
    if (loadingData.savedUnits) {
      return
    }
    if (savedUnits?.length > 0) {
      setSavedUnitsData(savedUnits)
      setIsFetching(false)
    } else {
      setIsFetching(false)
    }
  }, [savedUnits])

  return (
    <section className="min-h-[450px]">
      <div className="flex flex-wrap gap-4">
        {showActiveListings && filteredListings.length > 0 && (
          <div className="flex-grow">
            <div className="ml-auto w-fit">
              <OISortButton
                selectedOptionId={selectedSortOption?.id}
                onSelect={(option) => {
                  setSelectedSortOption(option)
                }}
                segmentBase={'profile_saved_listings'}
                sortOptions={sortOptions}
              />
            </div>
          </div>
        )}
      </div>
      {isFetching ? (
        <div className="my-8 flex items-center justify-center">
          <BeatLoader size={10} />
        </div>
      ) : (
        <>
          {filteredListings?.length > 0 ? (
            <ul className="my-8 grid gap-4 md:grid-cols-3">
              {filteredListings.map((listing) => (
                <li key={listing.id}>
                  <ListingUnitCards unit={listing} />
                </li>
              ))}
            </ul>
          ) : (
            <div className="my-8 flex flex-col items-center justify-center space-y-4">
              <div className="rounded-full bg-blue-25 p-2">
                <OIIcon name="heart" className="fill-transparent" size="md" />
              </div>
              <h6 className="text-lg font-semibold">No Saved Listings</h6>
              <p className="text-dark-700">Start saving listings, they will be displayed here.</p>
              <Link href={'/listings'} passHref>
                <OIPrimaryButton
                  onClick={() => segment.profile_saved_explore_listings()}
                  size={OIButtonSize.LARGE}
                  label="Explore listings"
                />
              </Link>
            </div>
          )}
        </>
      )}
    </section>
  )
}

export default SavedListings
