import { useRouter } from 'next/router'
import { useContext, useState } from 'react'
import { GrClose } from 'react-icons/gr'
import { segment } from 'src/lib/Segments'
import { APContextReview } from 'src/pages/review/[buildingSlug]'

import { ReviewSteps, titleCase } from '@openigloo/common'
import { useAppSelector } from '@redux/hooks'

import { ActionButton, Separator } from '..'

type Props = {
  steps?: ReviewSteps[]
  currentStep: number
  buildingSlug: string
  onSaveAndExit: () => void
  setLoader: (params: boolean) => void
}

const validSteps = Object.keys(ReviewSteps)
  .filter((k) => isNaN(Number(k)) && k !== 'LOGIN_SIGNUP')
  .map((k) => ReviewSteps[k])

function ReviewHeader({
  steps = validSteps,
  currentStep,
  buildingSlug,
  onSaveAndExit,
  setLoader,
}: Props) {
  const router = useRouter()
  const [showModal, setShowModal] = useState(false)
  const { profile, isLoggedIn } = useAppSelector((state) => state.currentUser)
  const { buildingData, reviewData } = useContext(APContextReview)

  const ExitConfirmModal = () => {
    return (
      <div
        className="fixed inset-0 z-[100] w-full overflow-hidden overflow-y-scroll bg-black bg-opacity-90 text-black"
        id="background"
        onClick={() => setShowModal(false)}
      >
        <div
          className="z-50 mx-auto my-32 w-11/12 overflow-hidden rounded-2xl bg-white sm:max-h-[calc(100vh-76px)] lg:w-2/5"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex items-center justify-end bg-white px-8 pt-6 pb-4 sm:rounded-t-2xl">
            <button
              className="focus:outline-none"
              onClick={() => {
                setShowModal(false)
              }}
            >
              <GrClose size={16} className="text-dark-900" />
            </button>
          </div>
          <div className="px-4 pb-8 text-center">
            <div className="text-2xl font-semibold text-dark-900">Oops! You're not done yet!</div>
            <div className="pt-4 pb-4 text-dark-700">
              Are you sure you want to exit this review? We depend on renters like you to share
              their experiences so we can hold landlords accountable.
            </div>
            <>
              <ActionButton
                label={'Continue to submit'}
                onClick={() => {
                  segment.review_continue_after_quit(buildingSlug, currentStep, profile?.email)
                  setShowModal(false)
                }}
              />
              <ActionButton
                label={'Save as draft'}
                style="none"
                customStyle="bg-white !text-dark-600 border !border-light-40 mt-4"
                onClick={() => {
                  segment.review_save_quit(buildingSlug, currentStep, profile?.email)
                  onSaveAndExit()
                  setShowModal(false)
                }}
              />
              {!isLoggedIn && (
                <ActionButton
                  label={'Discard changes & exit'}
                  style="none"
                  customStyle="bg-white !text-bluegray-300 text-sm border-none mt-4 underline"
                  onClick={() => {
                    segment.review_quit_discard(buildingSlug, currentStep, profile?.email)
                    setLoader(true)
                    router.back()
                    setShowModal(false)
                  }}
                />
              )}
            </>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="bg-white">
      <div
        className={`mx-auto px-3 pb-2 sm:px-4 sm:pt-6 lg:px-0 
        ${currentStep !== ReviewSteps.SUCCESS ? 'max-w-screen-xl' : 'max-w-screen-lg'}`}
      >
        {currentStep !== ReviewSteps.SUCCESS && (
          <>
            <div className="hidden justify-end sm:flex">
              <ActionButton
                label={
                  <div className="flex items-center text-sm">
                    Exit review <GrClose className="ml-2" />
                  </div>
                }
                style="none"
                size="small"
                customStyle="bg-white !text-dark-600 border border-light-40"
                onClick={() => {
                  reviewData.status !== 'draft' && isLoggedIn
                    ? router.push('/profile?q=reviews')
                    : setShowModal(true)
                }}
              />
            </div>
            <div className="py-4 text-center font-semibold text-dark-900">
              {titleCase(buildingData?.primaryAddress?.fullAddress)}
            </div>
          </>
        )}
        <div
          className={`flex items-center ${currentStep == ReviewSteps.SUCCESS && 'justify-center'}`}
        >
          {steps.map((_, index) => {
            return (
              <div
                className={`${
                  currentStep >= index ? 'bg-lilac-500' : 'bg-mid-60'
                } mx-1 h-1 w-full rounded-md sm:mx-4 ${
                  currentStep == ReviewSteps.SUCCESS && 'hidden'
                }`}
                key={index}
              />
            )
          })}
          {currentStep == ReviewSteps.SUCCESS && (
            <div className="mt-6 flex w-full items-center justify-center sm:mt-0">
              <div className="mx-auto text-center font-semibold text-dark-900">
                {titleCase(buildingData?.primaryAddress?.fullAddress)}
              </div>
              <div className="hidden justify-end pl-4 sm:flex">
                <ActionButton
                  label={
                    <div className="flex items-center justify-end text-sm">
                      Exit <GrClose className="ml-2" />
                    </div>
                  }
                  style="none"
                  size="xsmall"
                  customStyle="bg-white !text-dark-600 border border-light-40"
                  onClick={() => {
                    router.push('/profile?q=reviews')
                  }}
                />
              </div>
              <div className="block sm:hidden">
                <GrClose
                  className="ml-2"
                  color="#6764A1"
                  onClick={() => {
                    router.push('/profile?q=reviews')
                  }}
                />
              </div>
            </div>
          )}
          {currentStep !== ReviewSteps.SUCCESS && (
            <div className="block sm:hidden">
              <GrClose
                className="ml-2"
                color="#6764A1"
                onClick={() => {
                  reviewData.status !== 'draft' && isLoggedIn
                    ? router.push('/profile?q=reviews')
                    : setShowModal(true)
                }}
              />
            </div>
          )}
        </div>
        {currentStep !== ReviewSteps.SUCCESS && (
          <div className="hidden pt-4 text-center text-sm text-dark-600 sm:block">
            Your changes are automatically saved as you progress
          </div>
        )}
      </div>
      <Separator customStyle="mb-0" />
      {showModal && <ExitConfirmModal />}
    </div>
  )
}

export default ReviewHeader
