import { segment } from 'src/lib/Segments'

export default function CheckBox({
  options,
  selectedValues,
  onSelect,
  eventName = null,
  segmentBase = 'other',
  customCss = '',
  customStyle = '',
  labelCss = '',
}) {
  return (
    <div className={`my-4 grid gap-4 sm:grid-cols-2 ${customCss}`}>
      {options.map((value) => {
        const selected = selectedValues.includes(value)
        return (
          <div
            className={`flex w-full cursor-pointer flex-row items-center ${customStyle}`}
            key={value}
            onClick={() => {
              eventName && segment?.[segmentBase + '_radio_check_btn']?.(eventName, value)
              selected
                ? onSelect(selectedValues.filter((v) => v !== value))
                : onSelect([...selectedValues, value])
            }}
          >
            <input
              type="checkbox"
              name={value}
              className={`h-4 w-4 rounded-full border checked:bg-bluegray-300 `}
              checked={selected}
            />
            <label className={`ml-2 cursor-pointer text-sm text-dark-900 ${labelCss}`}>
              {value}
            </label>
          </div>
        )
      })}
    </div>
  )
}
