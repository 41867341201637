import React, { useEffect, useState } from 'react'

type ToolTipProps = {
  toolTipContent: React.ReactElement
  toolTipComponent: React.ReactElement
  customCss?: string
  customCssComp?: string
  id?: string
}

const ToolTip = ({
  toolTipContent,
  toolTipComponent,
  customCss = '',
  customCssComp = '',
}: ToolTipProps) => {
  const [active, setActive] = useState(false)

  useEffect(() => {
    window.addEventListener('click', function (e: any) {
      if (document.getElementById('clickbox')?.contains(e.target)) {
        console.log('')
      } else {
        setActive(false)
      }
    })
  }, [])

  return (
    <div
      onMouseOut={() => setActive(false)}
      onMouseOver={() => setActive(true)}
      onClick={() => setActive(true)}
      className={`relative hover:cursor-pointer ${customCssComp}`}
      id="clickbox"
    >
      {toolTipComponent}
      {active && (
        <div
          className={`absolute -top-5 z-20 w-max break-normal break-words rounded-xl
          border border-black bg-white p-1 text-left text-sm leading-normal text-black md:left-5 md:-top-0.5 ${customCss}`}
        >
          {toolTipContent}
        </div>
      )}
    </div>
  )
}
export default ToolTip
