import { useContext } from 'react'
import { TbCurrencyDollar } from 'react-icons/tb'
import { updateReview } from 'src/data/Review'
import { APContextReview } from 'src/pages/review/[buildingSlug]'

import { useAppSelector } from '@redux/hooks'

import { SectionSubheader } from '..'
import RadioButton from './RadioButton'
import ReviewTextInput from './ReviewTextInput'

type Props = {
  showError: boolean
}

function CreatePricing({ showError }: Props) {
  const { token, isLoggedIn } = useAppSelector((state) => state.currentUser)
  const { reviewData, setReviewData } = useContext(APContextReview)

  return (
    <div>
      <div className="rounded-xl bg-white p-3 sm:p-6 md:shadow">
        <div className="text-center text-xl font-semibold text-lilac-500 sm:text-left">Pricing</div>
        <div className="relative items-center pt-2 text-center sm:flex sm:text-left">
          <div className="pr-2 text-dark-700">
            Your pricing data is not published or connected to your review. We use this information
            to determine what percentage the landlord typically raises rents.
          </div>
        </div>
        <ReviewTextInput
          containerCss="max-w-full mt-4"
          customCss="pl-8"
          prompt="What was the rent for the entire apartment when you moved in?"
          placeholder="Net monthly rent at move in"
          value={reviewData.startingRent ? reviewData.startingRent.toString() : ''}
          setValue={(val) => {
            if (isLoggedIn) {
              updateReview(
                token,
                { startingRent: val.length > 0 ? parseInt(val) : null },
                reviewData.id
              )
            }
            setReviewData({
              ...reviewData,
              startingRent: val.length > 0 ? parseInt(val) : null,
              status: 'draft',
            })
          }}
          minWords={null}
          eventName="monthly_rent"
          required
          showError={showError}
          errorVal=""
          showValueError={true}
          icon={<TbCurrencyDollar color={`${!reviewData.startingRent ? '#5E6C84' : '#061938'}`} />}
        />
        <ReviewTextInput
          containerCss="max-w-full"
          customCss="pl-8"
          prompt="What was the rent for the entire apartment when you moved out (or currently)?"
          placeholder="Net monthly rent at move out or currently"
          value={reviewData.endingRent ? reviewData.endingRent.toString() : ''}
          setValue={(val) => {
            if (isLoggedIn) {
              updateReview(
                token,
                { endingRent: val.length > 0 ? parseInt(val) : null },
                reviewData.id
              )
            }
            setReviewData({
              ...reviewData,
              endingRent: val.length > 0 ? parseInt(val) : null,
              status: 'draft',
            })
          }}
          minWords={null}
          eventName="moved_out_rent"
          required
          showError={showError}
          errorVal=""
          showValueError={true}
          icon={<TbCurrencyDollar color={`${!reviewData.endingRent ? '#5E6C84' : '#061938'}`} />}
        />
        <ReviewTextInput
          containerCss="max-w-full"
          customCss="pl-8"
          prompt="If applicable, what is your average monthly electric bill?"
          placeholder="Avg. electric bill"
          value={
            reviewData.avgMonthlyElectricBill ? reviewData.avgMonthlyElectricBill.toString() : ''
          }
          setValue={(val) => {
            if (isLoggedIn) {
              updateReview(
                token,
                { avgMonthlyElectricBill: val.length > 0 ? parseInt(val) : null },
                reviewData.id
              )
            }
            setReviewData({
              ...reviewData,
              avgMonthlyElectricBill: val.length > 0 ? parseInt(val) : null,
              status: 'draft',
            })
          }}
          minWords={null}
          eventName="monthly_electric_bill"
          icon={
            <TbCurrencyDollar
              color={`${!reviewData.avgMonthlyElectricBill ? '#5E6C84' : '#061938'}`}
            />
          }
        />
        <ReviewTextInput
          containerCss="max-w-full"
          customCss="pl-8"
          prompt="If applicable, what is your average monthly gas bill?"
          placeholder="Avg. gas bill"
          value={reviewData.avgMonthlyGas ? reviewData.avgMonthlyGas.toString() : ''}
          setValue={(val) => {
            if (isLoggedIn) {
              updateReview(
                token,
                { avgMonthlyGas: val.length > 0 ? parseInt(val) : null },
                reviewData.id
              )
            }
            setReviewData({
              ...reviewData,
              avgMonthlyGas: val.length > 0 ? parseInt(val) : null,
              status: 'draft',
            })
          }}
          minWords={null}
          eventName="monthly_gas_bill"
          icon={<TbCurrencyDollar color={`${!reviewData.avgMonthlyGas ? '#5E6C84' : '#061938'}`} />}
        />
      </div>
      <div className="mt-6 rounded-xl bg-white p-3 sm:p-6 md:shadow">
        <div className="text-center text-xl font-semibold text-lilac-500 sm:text-left">
          Rent Stabilization
        </div>
        <div className={`mt-6 rounded-xl bg-light-10 p-4`}>
          <SectionSubheader style="text-dark-700 font-semibold text-base">
            Do you live in a rent-stabilized apartment?
          </SectionSubheader>
          <RadioButton
            style="mt-4"
            options={{ Yes: 'yes', No: 'no', 'Not Sure': 'not_sure' }}
            selectedValue={reviewData.rentStabilized}
            onSelect={(val: string) => {
              let newVal = val
              if (val == reviewData.rentStabilized) {
                newVal = null
              }
              if (isLoggedIn) {
                updateReview(token, { rentStabilized: newVal }, reviewData.id)
              }
              setReviewData({ ...reviewData, rentStabilized: newVal, status: 'draft' })
            }}
            eventName="rent_stabilized"
            segmentBase="review"
          />
          <div className="mt-4">
            <SectionSubheader style="text-dark-700 font-semibold text-base">
              Can you share your unit number with us?
            </SectionSubheader>
            <ReviewTextInput
              containerCss="max-w-full py-0"
              prompt={null}
              placeholder="Apartment Unit"
              value={reviewData.unitNumber ?? ''}
              setValue={(val) => {
                if (isLoggedIn) {
                  updateReview(token, { unitNumber: val }, reviewData.id)
                }
                setReviewData({ ...reviewData, unitNumber: val, status: 'draft' })
              }}
              minWords={null}
              eventName="apt_unit_number"
            />
          </div>
          <div className="mt-4 text-sm text-dark-500">
            These info is not publicly shared or attached to your review.
          </div>
        </div>
        <div className={`mt-6 rounded-xl bg-light-10 p-4`}>
          <SectionSubheader style="text-dark-700 font-semibold text-base">
            Are you the recipient of any rent subsidies such as Section 8, CITYFEPS or SEPS?
            (optional)?
          </SectionSubheader>
          <RadioButton
            style="mt-4"
            options={{ Yes: true, No: false }}
            selectedValue={reviewData.hasSubsidies}
            onSelect={(val) => {
              let newVal = val
              if (val == reviewData.hasSubsidies) {
                newVal = null
              }
              if (isLoggedIn) {
                updateReview(token, { hasSubsidies: newVal }, reviewData.id)
              }
              setReviewData({ ...reviewData, hasSubsidies: newVal, status: 'draft' })
            }}
            eventName="recipient_rent_subsidies"
            segmentBase="review"
          />
          <div className="mt-4 text-sm text-dark-500">
            This info will not be publicly shared or attached to your review. By sharing this info,
            we can try to help renters find voucher friendly landlords.
          </div>
        </div>
        <div className={`mt-6 rounded-xl bg-light-10 p-4`}>
          <SectionSubheader style="text-dark-700 font-semibold text-base">
            Does the building accept electronic rent payments?
          </SectionSubheader>
          <RadioButton
            style="mt-4"
            options={{ Yes: 'yes', No: 'no', 'Not Sure': 'not_sure' }}
            selectedValue={reviewData.acceptsElectronicRent}
            onSelect={(val) => {
              let newVal = val
              if (val == reviewData.acceptsElectronicRent) {
                newVal = null
              }
              if (isLoggedIn) {
                updateReview(token, { acceptsElectronicRent: newVal }, reviewData.id)
              }
              setReviewData({ ...reviewData, acceptsElectronicRent: newVal, status: 'draft' })
            }}
            eventName="electronic_rent_payments"
            segmentBase="review"
          />
        </div>
        <div className={`mt-6 rounded-xl bg-light-10 p-4`}>
          <SectionSubheader style="text-dark-700 font-semibold text-base">
            Is heat and hot water included in the rent?
          </SectionSubheader>
          <RadioButton
            style="mt-4"
            options={{ Yes: 'yes', No: 'no', 'Not Sure': 'not_sure' }}
            selectedValue={reviewData.heatAndHotWaterInRent}
            onSelect={(val) => {
              let newVal = val
              if (val == reviewData.heatAndHotWaterInRent) {
                newVal = null
              }
              if (isLoggedIn) {
                updateReview(token, { heatAndHotWaterInRent: newVal }, reviewData.id)
              }
              setReviewData({ ...reviewData, heatAndHotWaterInRent: newVal, status: 'draft' })
            }}
            eventName="heat_water_included"
            segmentBase="review"
          />
        </div>
      </div>
    </div>
  )
}

export default CreatePricing
