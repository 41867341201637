import { useRouter } from 'next/router'
import { useDispatch } from 'react-redux'
import slugify from 'slugify'
import { getFirebaseAuth } from 'src/lib/firebase'

import { logoutCurrentUser, setIsLoggedIn } from '@redux/actions'
import { listingSlice } from '@redux/reducers/listing'

const useLogout = () => {
  const dispatch = useDispatch()
  const router = useRouter()

  const logout = () => {
    const auth = getFirebaseAuth()
    auth.signOut()
    dispatch(logoutCurrentUser())
    dispatch(setIsLoggedIn(false))
    dispatch(listingSlice.actions.clearLastListingInterestBody())
    const cityObj = JSON.parse(localStorage.getItem('city'))
    cityObj?.name?.toLowerCase() !== 'new york city'
      ? router.push(`/${slugify(cityObj?.name?.toLowerCase() ?? '')}`)
      : router.push(`/`)
  }

  return logout
}

export default useLogout
