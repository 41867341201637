import { createUserWithEmailAndPassword } from 'firebase/auth'
import { useState } from 'react'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { verifyEmail } from 'src/data/newUserProfile'
import { segment } from 'src/lib/Segments'
import { getFirebaseAuth } from 'src/lib/firebase'
import isMobilePhone from 'validator/lib/isMobilePhone'

import { OIButton, OIInput } from '@components/UI/atoms'
import RadioButton from '@components/review/RadioButton'
import { setAuthRequired, setCurrentUserModifyProfile } from '@redux/actions'
import { useAppSelector, useThunkDispatch } from '@redux/hooks'
import { userSlice } from '@redux/reducers/user'
import { formatPhoneNumber } from '@utility/Utilities'

import { SectionSubheader } from '..'

interface SignupProps {
  email: string
  token: string
  onError?: (error: string) => void
}

const Signup = ({ email, token, onError }: SignupProps) => {
  const dispatch = useThunkDispatch()
  const { profile, authRequired } = useAppSelector((state) => state.currentUser)

  const [firstName, setFirstName] = useState<string>()
  const [lastName, setLastName] = useState<string>()
  const [password, setPassword] = useState<string>()
  const [phone, setPhone] = useState<string>(authRequired?.phonePrefill ?? profile?.phone ?? '')
  const [showPassword, setShowPassword] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [phoneError, setPhoneError] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [consent, setConsent] = useState(true)

  const handleSignup = async () => {
    let authToken = token
    try {
      if (!authToken) {
        const result = await createUserWithEmailAndPassword(getFirebaseAuth(), email, password)
        authToken = await result.user.getIdToken()
        segment.login_signup_signupbutton(email, '')
      }

      const data = {
        firstName: firstName,
        lastName: lastName,
        phone: phone,
      }
      dispatch(userSlice.actions.setConsentEnabled(consent))
      await dispatch(setCurrentUserModifyProfile(authToken, data))
      await verifyEmail(authToken)
      dispatch(setAuthRequired(false))
    } catch (e) {
      onError('Something went wrong!')
    }
  }

  return (
    <div className="slide-left">
      <div className="py-4 text-xl font-semibold text-dark-900">Sign up</div>
      <div className="grid grid-cols-2 gap-x-4 gap-y-2">
        <div className="space-y-1">
          <SectionSubheader style="!text-sm">First name</SectionSubheader>
          <OIInput
            autoFocus
            autoComplete="off"
            placeholder="First"
            className="text-sm"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          {firstName && !firstName.trim().length && (
            <div className="-mt-3 text-xs text-red-400">Enter first name</div>
          )}
        </div>
        <div className="space-y-1">
          <SectionSubheader style="!text-sm">Last name</SectionSubheader>
          <OIInput
            autoComplete="off"
            placeholder="Last"
            className="text-sm"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          {lastName && !lastName.trim().length && (
            <div className="-mt-3 text-xs text-red-400">Enter last name</div>
          )}
        </div>

        <div className="col-span-2 space-y-1 ">
          <SectionSubheader style="!text-sm -mb-4">Phone</SectionSubheader>
          <OIInput
            type={'tel'}
            placeholder="Your phone number"
            className="text-sm"
            value={phone}
            invalid={phone && !isMobilePhone(phone, 'en-US')}
            onChange={(e) => {
              setPhone(formatPhoneNumber(e.target.value))
              // setPhoneError(false)
            }}
            onBlur={() => {
              if (phone && !isMobilePhone(phone, 'en-US')) {
                setPhoneError(true)
              } else {
                // setPhoneError(false)
              }
            }}
          />
          {phoneError && !isMobilePhone(phone, 'en-US') && (
            <div className="-mt-3 text-xs text-red-400">Please enter a valid phone number</div>
          )}
        </div>
      </div>
      <div className="pt-4">
        <RadioButton
          selectedValue={consent}
          customStyle={'!items-start'}
          options={{
            'Sign me up for text alerts! Get the latest listings and updates from openigloo. Reply STOP to unsubscribe.':
              true,
          }}
          onSelect={async () => {
            if (consent) {
              segment?.unit_contact_agent_sms_consent_uncheck()
              setConsent(false)
            } else {
              segment?.unit_contact_agent_sms_consent_check()
              setConsent(true)
            }
          }}
        />
      </div>
      <div className="my-3 flex flex-col space-y-1">
        <SectionSubheader style="!text-sm">Your email</SectionSubheader>
        <OIInput
          className="text-sm"
          onChange={() => null}
          placeholder="Your email"
          disabled={!!email}
          value={email}
        />
      </div>

      {!token ? (
        <div className="my-3 flex flex-col space-y-1">
          <SectionSubheader style="!text-sm -mb-4">Password</SectionSubheader>
          <div className="relative w-full">
            {showPassword ? (
              <AiOutlineEyeInvisible
                size={17}
                className="absolute right-4 top-1/2 z-10 -translate-y-1/2 text-bluegray-300"
                onClick={() => setShowPassword(!showPassword)}
              />
            ) : (
              <AiOutlineEye
                size={17}
                className="absolute right-4 top-1/2 z-10 -translate-y-1/2 text-bluegray-300"
                onClick={() => setShowPassword(!showPassword)}
              />
            )}

            <OIInput
              className="text-sm"
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Set your password"
              value={password}
              type={showPassword ? 'text' : 'password'}
              onBlur={() => {
                setPasswordError(true)
              }}
            />
            {password?.length < 6 && passwordError && (
              <div className="-mt-3 text-xs text-red-400">
                Password requires minimum 6 characters
              </div>
            )}
          </div>
        </div>
      ) : null}

      <div className="mb-6 -mt-1 space-y-2 text-xs text-dark-500">
        <p>Make sure your name matches with your Government ID</p>
        <p>
          This is <b className="font-semibold">NOT</b> shared on any reviews but we need this to
          help verify your identity if you apply or inquire about any apartments.
        </p>
      </div>

      <OIButton
        onClick={() => {
          setIsSubmitting(true)
          handleSignup()
        }}
        isLoading={isSubmitting}
        disabled={
          ((!password || password?.length < 6) && !token) ||
          !firstName?.trim().length ||
          !lastName?.trim().length ||
          !isMobilePhone(phone, 'en-US') ||
          isSubmitting
        }
        style="!w-full"
        label="Sign up"
      />
    </div>
  )
}

export default Signup
