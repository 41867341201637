import { useEffect, useState } from 'react'
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share'

import { HiOutlineClipboardCopy } from '@react-icons/all-files/hi/HiOutlineClipboardCopy'

const CopyShare = ({ url, onShare }) => {
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 600)
    }
  }, [copied])

  const handleCopyUrl = () => {
    setCopied(true)
    navigator.clipboard.writeText(url)
    onShare('_share_copyurl')
  }
  return (
    <div className="flex cursor-pointer items-center rounded-full bg-bluegray-300 p-2 text-white">
      {copied ? (
        <span className="text-xxs">Copied</span>
      ) : (
        <HiOutlineClipboardCopy title="Copy to clipboard" onClick={handleCopyUrl} />
      )}
    </div>
  )
}

const ShareOptions = ({
  title,
  url,
  customEmailBody = null,
  customEmailTitle = null,
  onShare = () => {},
}: {
  title: string
  url: string
  customEmailTitle?: string
  customEmailBody?: string
  onShare?: (v: string) => void
}) => {
  return (
    <>
      <CopyShare url={url} onShare={onShare} />
      <EmailShareButton
        subject={customEmailTitle ?? title}
        url={customEmailBody ?? url}
        beforeOnClick={() => onShare('_share_email')}
      >
        <EmailIcon size="32" round={true} />
      </EmailShareButton>
      <TwitterShareButton
        title={title}
        url={url}
        via="openigloo"
        beforeOnClick={() => onShare('_share_twitter')}
      >
        <TwitterIcon size="32" round={true} />
      </TwitterShareButton>
      <FacebookShareButton url={url} quote={title} beforeOnClick={() => onShare('_share_facebook')}>
        <FacebookIcon size="32" round={true} />
      </FacebookShareButton>
    </>
  )
}

export default ShareOptions
