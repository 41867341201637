import { segment } from 'src/lib/Segments'

import { AiOutlineInstagram } from '@react-icons/all-files/ai/AiOutlineInstagram'
import { AiOutlineTwitter } from '@react-icons/all-files/ai/AiOutlineTwitter'
import { FaFacebook } from '@react-icons/all-files/fa/FaFacebook'
import { FaLinkedinIn } from '@react-icons/all-files/fa/FaLinkedinIn'
import { SiTiktok } from '@react-icons/all-files/si/SiTiktok'

export default function SocialLinks() {
  return (
    <div className="flex items-center space-x-2 text-bluegray-500 sm:mt-0">
      <div className="h-6 w-6 overflow-hidden rounded-sm">
        <a
          onClick={segment.footer_instagram}
          className="hover:opacity-80"
          href="https://www.instagram.com/openigloo"
          target="_blank"
          rel="noreferrer nofollow"
          title="instagram"
        >
          <AiOutlineInstagram size="1.5em" className="relative" />
        </a>
      </div>
      <div className="h-6 w-6 overflow-hidden rounded-sm">
        <a
          onClick={segment.footer_facebook}
          className="hover:opacity-80"
          href="https://www.facebook.com/openigloo"
          target="_blank"
          rel="noreferrer nofollow"
          title="facebook"
        >
          <FaFacebook size="1.1em" className="relative top-1 mx-auto" />
        </a>
      </div>
      <div className="h-6 w-6 overflow-hidden rounded-sm">
        <a
          onClick={segment.footer_linkedin}
          className="hover:opacity-80"
          href="https://www.linkedin.com/company/openigloo/"
          target="_blank"
          rel="noreferrer nofollow"
          title="linkedin"
        >
          <FaLinkedinIn size="1.2em" className="relative top-1 mx-auto" />
        </a>
      </div>
      <div className="h-6 w-6 overflow-hidden rounded-sm">
        <a
          onClick={segment.footer_twitter}
          className="hover:opacity-80"
          href="https://twitter.com/openigloo"
          target="_blank"
          rel="noreferrer nofollow"
          title="twitter"
        >
          <AiOutlineTwitter size="1.2em" className="relative top-1" />
        </a>
      </div>
      <div className="h-6 w-6 overflow-hidden rounded-sm">
        <a
          onClick={segment.footer_tiktok}
          className="hover:opacity-80"
          href="https://www.tiktok.com/@openigloo_nyc"
          target="_blank"
          rel="noreferrer nofollow"
          title="tiktok"
        >
          <SiTiktok size="1.2em" className="relative top-1" />
        </a>
      </div>
    </div>
  )
}
